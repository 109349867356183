import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

function NewAdvertisementSuccess(){
    const [searchParams] = useSearchParams();

    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1>Sukces!</h1>
                        <p>Płatność została zatwierdzona i ogłoszenie dodane. Jest aktualnie sprawdzane.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default NewAdvertisementSuccess;