import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

function SignUpConfirmation(){
    let [searchParams, setSearchParams] = useSearchParams();
    let user_email = searchParams.get("email");

    return(
        <section>
            <Container>
                <Row>
                    <Col xs={12} md={{span: 6, offset: 3}}>
                        <h2>Super! Wysłaliśmy email.</h2>
                        <p>Rejestracja przebiegła pomyślnie. Potwierdź konto klikając w linka którego otrzymałeś na podany adres email {user_email}.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SignUpConfirmation;