import React, {useState, useEffect} from "react";
import "./MyAdvertisements.scss";
import { Card, Button, Container, Row, Col, Nav, Pagination, Modal, Spinner } from "react-bootstrap";
import announcement_thumbnail from "./../assets/volkswagen.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { supabase } from "../supabase_client";
import {connect} from "react-redux";

function MyAdvertisements(props){
    const [advertisements, setAdvertisements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);
    const [advertisementsLength, setAdvertisementsLength] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [announcementToDelete, setAnnouncementToDelete] = useState(null);
    const navigate = useNavigate();

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const handleModalDelete = (e, id_to_delete) => {
        e.preventDefault();
        handleModalShow();
        setAnnouncementToDelete(id_to_delete);
    }

    function delete_announcement(e){
        e.preventDefault();

        return supabase.from("car_announcements")
        .delete()
        .eq("id", announcementToDelete)
        .then(({error}) => {
            if(error) throw new Error(error);
            
            setShowModal(false);

            // reload announcements
            setLoading(true);
            get_my_announcements(itemOffset, itemOffset + itemsPerPage - 1);  
        })
        .catch((error) => {
        })
    }

    function handlePageClick(event){
        setLoading(true);
        const newOffset = (event.selected * itemsPerPage) % advertisementsLength;
        setItemOffset(newOffset);
    };

    function get_announcements_count(){
        return supabase.from('car_announcements').select('*', { count: 'exact', head: true }).eq("user_id", props.authentication.user.user.id)
        .then((result) => {
            setAdvertisementsLength(result.count);
        });
    }

    function setStatus(status){
        let text = "";

        switch(status) {
            case "UNPAID": 
                text = "Nieopłacone";
                break;
            case "PAID":
                text = "Weryfikacja";
                break;
            case "PAYMENT_FAIL":
                text = "Błąd płatności";
                break;
            case "COMPLETED":
                text = "Aktywne";
                break;
            default:
                text = "";
        }

        return text;
    }

    function setStatusClass(status){
        let classes = "";

        switch(status) {
            case "UNPAID":
                classes = "status-danger";
                break;
            case "PAID": 
                classes = "status-checking";
                break;
            case "PAYMENT_FAIL":
                classes = "status-danger";
                break;
            case "COMPLETED":
                classes = "status-success";
                break;
            default: 
                classes = "";
        }

        return classes;
    }

    function get_my_announcements(from, to){
        return supabase.from("car_announcements").select(`
            id,
            car_brands (name),
            car_models (name),
            car_generations (name),
            created_at,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            end_date,
            status,
            files (url)
        `)
        .eq("user_id", props.authentication.user.user.id)
        .range(from, to)
        .then(({data, error}) => {
            // change date to year
            for(let i = 0; i < data.length; i++){
                let new_year = new Date(data[i].production_year);
                data[i].production_year = new_year.getFullYear();
            }

            setAdvertisements(data);
            setLoading(false);
        })
    }

    useEffect(() => {
        setPageCount(Math.ceil(advertisementsLength / itemsPerPage));
        get_my_announcements(itemOffset, itemOffset + itemsPerPage - 1);
        get_announcements_count();

    }, [itemOffset, advertisementsLength])


    return (<>
    <section className="mb-48">
        <Container>
            <Row>
                <Col xs={12} className="mb-40 mt-16">
                    <h1>Moje ogłoszenia</h1>
                </Col>
            </Row>

            <Row>
                {advertisementsLength > 0 ? 
                <>
                <Col xs={12}>
                    <div className="mb-16">
                        <Nav variant="pills" defaultActiveKey="all">
                            <Nav.Item>
                                <Nav.Link eventKey="all">Wszystkie</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="active">Aktywne</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="unactive">Nieaktywne</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    </div>
                </Col>
                        
                {loading ?
                (<Col xs={12} className="d-flex justify-content-center py-24">
                    <Spinner animation="grow" variant="secondary" />
                </Col>)
                :
                null
                }
                
                <Col xs={12} className="d-none d-md-block">
                    {loading ? 
                    null 
                    : 
                    <div className="py-8 px-16 d-flex text-grey-300">
                        <div className="w-180">

                        </div>
                        <div className="flex-basis-44 ms-16">
                            <p className="mb-0">Nazwa</p>
                        </div>
                        <div className="flex-basis-15 ms-16">
                            <p className="mb-0">Status</p>
                        </div>
                        <div className="ms-16">
                            
                        </div>
                    </div>
                    }
                </Col>
                <Col xs={12}>
                    {loading ?
                    null
                    :
                    advertisements.map((item) => {
                        return (
                            <div className="d-md-flex rounded-8 shadow-sm overflow-hidden mb-8 p-16" key={item.id}>
                                <div className="w-180 h-104 overflow-hidden rounded-8">
                                    <img src={item.files[0].url} className="w-100 h-auto" />
                                </div>
                                <div className="ms-md-16 flex-basis-44">
                                    <h2 className="h4"><Link to={"/samochody/" + item.id} className="text-decoration-none text-grey-900">{item.car_brands.name + " " + item.car_models.name + ", " + item.production_year + "r."}</Link></h2>
                                    <p className="mb-0 text-grey-500">Pojemność silnika: {item.engine_capacity}cm3</p>
                                    <p className="mb-0 text-grey-500">Moc: {item.engine_power}KM</p>
                                </div>
                                <div className="pt-36 ms-md-16 flex-basis-15">
                                    <span className={setStatusClass(item.status)}>{setStatus(item.status)}</span>
                                </div>
                                <div className="pt-36 ms-md-auto">
                                    <Link className="me-16 fs-20 text-body" to={"/moje-ogloszenia/edit/" + item.id}>
                                        <i className="bi bi-pencil"></i>
                                    </Link>
                                    <a href="" onClick={(event) => handleModalDelete(event, item.id)} className="me-16 fs-20 text-body">
                                        <i className="bi bi-trash"></i>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </Col>
                </>
                :
                <Col xs={12} className="py-40 text-center">
                    <p className="mb-24">Nie posiadasz żadnych ogłoszeń.</p>
                    <Link to="/nowe-ogloszenie" className="btn btn-secondary">Dodaj ogłoszenie</Link>
                </Col>
                }
                
                <Col xs={12} className="d-flex justify-content-center mt-40">
                    <ReactPaginate
                        nextLabel="Następne"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        previousLabel="Poprzednie"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="link-previous"
                        previousLinkClassName="link-previous-link"
                        nextClassName="link-next"
                        nextLinkClassName="link-next-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </Col>
            </Row>
        </Container>
    </section>

    <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
            <Modal.Title>Usuwanie ogłoszenia</Modal.Title>
        </Modal.Header>
        <Modal.Body>Czy napewno chcesz usunąć to ogłoszenie?</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>Anuluj</Button>
            <Button variant="primary" onClick={(e) => delete_announcement(e)}>Usuń</Button>
        </Modal.Footer>
    </Modal>
    </>
    )
}

export default connect(
    (state) => ({authentication: state.authentication})
)(MyAdvertisements);