import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import TrashIcon from "bootstrap-icons/icons/trash.svg";
import "./DropzoneImages.scss";
import Compressor from 'compressorjs';

// add path??  - path isnt necessary
// resize, only width, what about height? compressor.js

const thumbsContainer = {
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 120,
    height: 120,
    padding: 4,
    boxSizing: 'border-box',
    position: "relative"
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px 20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function DropzoneImages(props) {
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/gif": [".gif"],
            "image/bmp": [".bmp"]
        },
        onDrop: (acceptedFiles, fileRejections) => {
            let errorText = null;
            let compressedImages = [];
            let acceptedFilesPromises = [];

            setLoading(true);

            // ACCEPTED FILES
            acceptedFiles.forEach((item) => {
                acceptedFilesPromises.push(
                    new Promise((resolve, reject) => {
                        new Compressor(item, {
                            quality: 0.8,
                            success: resolve,
                            error: function(err) {
                            },
                            maxWidth: 1920,
                            convertTypes: ["image/png", "image/gif", "image/bmp"], // convert tp JPEG
                            convertSize: 1024 // files above this size will be converted to JPG, 1kB
                        });
                    })
                );
            });

            Promise.all(acceptedFilesPromises)
                .then((values) => {
                    values.forEach((element) => {
                        compressedImages.push(
                            // Convert blob to file
                            new File([element], element.name, {
                                type: element.type
                            }
                        ));
                    });
                })
                .then((result) => {
                    let newFiles = [...files, ...compressedImages.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}))];
                    setFiles(newFiles);
                    props.onChange(newFiles);
                    setLoading(false);
                });
            
            // REJECTED FILES
            fileRejections.forEach((element) => {
                element.errors.forEach((element) => {
                    switch(element.code){
                        case "file-too-large":
                            errorText = "Wielkość pliku jest zbyt duża. Maksymalnie plik może ważyć 10 megabajtów.";
                            break;
                        case "the-same-name":
                            errorText = "Próbujesz wgrać ten sam plik."
                            break;
                        default:
                            return;
                    }
                })
            });

            setErrors(errorText);
        },
        multiple: true,
        validator: nameValidator,
        maxSize: 10485760 // 10 megabytes
    });

    function nameValidator(file) {
        for(let i = 0 ; i < files.length ; i++){
            if(file.name === files[i].name){
                return {
                    code: "the-same-name",
                    message: "Próbujesz wgrać ten sam obraz."
                }
            }
        }

        return null;
    }

    function removeFile(file){
        let newFiles = [...files];
        newFiles.splice(files.indexOf(file), 1);
        setFiles(newFiles);

        props.onChange(newFiles);
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        setFiles(props.images);

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [props.images]);

    return (
        <div>
            <div {...getRootProps({ style: style })}>
                <input {...getInputProps()} />
                <p className="mb-0">Upuść zdjęcia, aby je dodać lub kliknij tu.</p>
            </div>
            <aside style={thumbsContainer}>
                {files.map(file => (
                    <div style={thumb} key={file.name}>
                        <div style={thumbInner}>
                            <img
                                src={file.preview}
                                style={img}
                                alt=""
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                            <span className="dropzone-delete" onClick={(e) => {removeFile(file)}}><img src={TrashIcon} alt="" /></span>
                        </div>
                    </div>
                ))
                }
                {errors && (<div><p className="fs-14 text-danger">{errors}</p></div>)}
            </aside>
        </div>
    );
}

export default DropzoneImages;