import React, {useState, useEffect} from "react";
import { Card, Button, Container, Row, Col, Tab, Tabs, Form, Spinner, InputGroup } from "react-bootstrap";
import { supabase } from "../supabase_client";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Select from 'react-select';

function Cars(props){
    const [pageCount, setPageCount] = useState(0);
    const [advertisementsLength, setAdvertisementsLength] = useState(0);
    const [advertisementsFilteredLength, setAdvertisementsFilteredLength] = useState(0);
    const [advertisements, setAdvertisements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);
    const [carBrands, setCarBrands] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [carGenerations, setCarGenerations] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [voivodeships, setVoivodeships] = useState([]);
    const [model, setModel] = useState(null);
    const [brand, setBrand] = useState(null);
    const [generation, setGeneration] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [priceFrom, setPriceFrom] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [mileageFrom, setMileageFrom] = useState("");
    const [mileageTo, setMileageTo] = useState("");
    const [voivodeship, setVoivodeship] = useState(null);
    const [city, setCity] = useState("");
    const [errors, setErrors] = useState({
        brand: {
            error: false,
            message: ""
        },
        model: {
            error: false,
            message: ""
        },
        generation: {
            error: false,
            message: ""
        },
        fuelType: {
            error: false,
            message: ""
        },
        bodyType: {
            error: false,
            message: ""
        },
        priceFrom: {
            error: false,
            message: ""
        }, 
        priceTo: {
            error: false, 
            message: ""
        }, 
        mileageFrom: {
            error: false,
            message: ""
        }, 
        mileageTo: {
            error: false,
            message: ""
        }, 
        voivodeship: {
            error: false, 
            message: ""
        }, 
        city: {
            error: false,
            message: ""
        }
    });

    function get_announcements_count(){
        return supabase.from('car_announcements').select('*', { count: 'exact', head: true }).eq("status", "COMPLETED")
        .then((result) => {
            setAdvertisementsLength(result.count);
        });
    }

    // function get_filtered_announcements_count(){
    //     let query = supabase.from('car_announcements').select('*', { count: 'exact', head: true }).eq("status", "COMPLETED");
        
    //     if(brand) query = query.eq("car_brand_id", brand.value);
    //     if(model) query = query.eq("car_model_id", model.value);
    //     if(generation) query = query.eq("car_generation_id", generation.value);
    //     if(fuelType) query = query.eq("fuel_type_id", fuelType.value);
    //     if(priceFrom !== "") query = query.gte("price", priceFrom);
    //     if(priceTo !== "") query = query.lte("price", priceTo);
    //     if(mileageFrom !== "") query = query.gte("mileage", mileageFrom);
    //     if(mileageTo !== "") query = query.lte("mileage", mileageTo);
    //     if(voivodeship) query = query.eq("voivodeship_id", voivodeship.value);

    //     return query.then((result) => {
    //         setAdvertisementsFilteredLength(result.count);
    //     });
    // }

    function getCarBrands(){
        return supabase.from("car_brands").select()
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarBrands(new_data);
        });
    }

    function getCarModels(brand_id){
        return supabase.from("car_models").select().eq("car_brand_id", brand_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            
            setCarModels(new_data);
        });
    }

    function getCarGenerations(model_id){
        return supabase.from("car_generations").select().eq("car_model_id", model_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarGenerations(new_data);
        })
    }

    function getCarFuelTypes(){
        return supabase.from("fuel_types").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setFuelTypes(new_data);
        });
    }

    function getVoivodeshipsOptions(){
        return supabase.from("voivodeships").select();
    }

    function get_my_announcements(from, to){
        return supabase.from("car_announcements").select(`
            id,
            car_brands (name),
            car_models (name),
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit, 
            mileage, 
            price, 
            voivodeships (name), 
            fuel_types (name), 
            city,
            files (url)
        `)
        .eq("status", "COMPLETED")
        .range(from, to)
        .then(({data, error}) => {
            // change date to year
            for(let i = 0; i < data.length; i++){
                let new_year = new Date(data[i].production_year);
                data[i].production_year = new_year.getFullYear();
            }

            setAdvertisements(data);
            setLoading(false);
        })
    }

    function handlePageClick(event){
        setLoading(true);
        const newOffset = (event.selected * itemsPerPage) % advertisementsLength;
        setItemOffset(newOffset);
    };

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);

        let query = supabase.from("car_announcements").select(`
            id,
            car_brands (name),
            car_models (name),
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit, 
            mileage, 
            price, 
            voivodeships (name), 
            fuel_types (name), 
            city,
            files (url)
        `)
        .eq("status", "COMPLETED");

        if(brand) query = query.eq("car_brand_id", brand.value);
        if(model) query = query.eq("car_model_id", model.value);
        if(generation) query = query.eq("car_generation_id", generation.value);
        if(fuelType) query = query.eq("fuel_type_id", fuelType.value);
        if(priceFrom !== "") query = query.gte("price", priceFrom);
        if(priceTo !== "") query = query.lte("price", priceTo);
        if(mileageFrom !== "") query = query.gte("mileage", mileageFrom);
        if(mileageTo !== "") query = query.lte("mileage", mileageTo);
        if(voivodeship) query = query.eq("voivodeship_id", voivodeship.value);

        query = query.range(0, 500);
        query.then(({data, error}) => {
            // change date to year
            for(let i = 0; i < data.length; i++){
                let new_year = new Date(data[i].production_year);
                data[i].production_year = new_year.getFullYear();
            }

            setAdvertisements(data);
            setLoading(false);
        });
    }
    
    useEffect(() => {
        setPageCount(Math.ceil(advertisementsLength / itemsPerPage));
        get_my_announcements(itemOffset, itemOffset + itemsPerPage - 1);
        get_announcements_count();

        getCarBrands();
        getCarFuelTypes();

        getVoivodeshipsOptions()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            });

            setVoivodeships(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

    }, [itemOffset, advertisementsLength])

    return (
        <section className="mb-16">
        <Container>
            <Row>
                <Col xs={12} className="mb-8 mt-16">
                    <h1>Samochody osobowe</h1>
                    <p>Wszystkich ogłoszeń: {advertisementsLength}</p>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="">
                    <div className="mb-16 p-24 bg-light rounded-24">
                        {/* <a href="" className="btn btn-primary" onChange={(e) => {e.preventDefault()}}>Filtruj wyniki</a> */}
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <Row>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Select 
                                                options={carBrands}
                                                onChange={(value) => {
                                                    setBrand(value);
                                                    setModel(null); // reset model select value
                                                    getCarModels(value.value);
                                                    setGeneration(null);
                                                    setCarGenerations([]);
                                                }} 
                                                value={brand}
                                                placeholder="Marka" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={true} 
                                            />
                                            {errors.brand.error && (<p className="fs-14 text-danger">{errors.brand.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Select 
                                                options={carModels} 
                                                onChange={(value) => {
                                                    setModel(value); // set model value
                                                    setGeneration(null); // reset generation select value
                                                    getCarGenerations(value.value);
                                                }} 
                                                placeholder="Model"
                                                value={model}
                                                isDisabled={carModels.length === 0 ? true : false} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false}
                                            />
                                            {errors.model.error && (<p className="fs-14 text-danger">{errors.model.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Select 
                                                options={carGenerations}
                                                onChange={(value) => {
                                                    setGeneration(value);
                                                }} 
                                                value={generation} 
                                                placeholder="Generacja" 
                                                isDisabled={carGenerations.length === 0 ? true : false} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false}
                                            />
                                            {errors.generation.error && (<p className="fs-14 text-danger">{errors.generation.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Select 
                                                options={fuelTypes}
                                                onChange={(value) => {
                                                    setFuelType(value);
                                                }} 
                                                value={fuelType} 
                                                placeholder="Rodzaj paliwa" 
                                                isDisabled={fuelTypes.length === 0 ? true : false} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={true}
                                            />
                                            {errors.fuelType.error && (<p className="fs-14 text-danger">{errors.fuelType.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Cena od" step={1} isInvalid={errors.priceFrom.error} value={priceFrom} onChange={(e) => {setPriceFrom(e.target.value)}} />
                                                <InputGroup.Text>zł</InputGroup.Text>
                                                {errors.priceFrom.error && (<Form.Control.Feedback type="invalid">{errors.priceFrom.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Cena do" step={1} isInvalid={errors.priceTo.error} value={priceTo} onChange={(e) => {setPriceTo(e.target.value)}} />
                                                <InputGroup.Text>zł</InputGroup.Text>
                                                {errors.priceTo.error && (<Form.Control.Feedback type="invalid">{errors.priceTo.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Przebieg od" step={1} isInvalid={errors.mileageFrom.error} value={mileageFrom} onChange={(e) => {setMileageFrom(e.target.value)}} />
                                                <InputGroup.Text>km</InputGroup.Text>
                                                {errors.mileageFrom.error && (<Form.Control.Feedback type="invalid">{errors.mileageFrom.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Przebieg do" step={1} isInvalid={errors.mileageTo.error} value={mileageTo} onChange={(e) => {setMileageTo(e.target.value)}} />
                                                <InputGroup.Text>km</InputGroup.Text>
                                                {errors.mileageTo.error && (<Form.Control.Feedback type="invalid">{errors.mileageTo.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Select 
                                                options={voivodeships}
                                                onChange={(value) => {
                                                    setVoivodeship(value);
                                                }} 
                                                value={voivodeship} 
                                                placeholder="Województwo" 
                                                isDisabled={voivodeships.length === 0 ? true : false} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={true}
                                            />
                                            {errors.voivodeship.error && (<p className="fs-14 text-danger">{errors.voivodeship.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs={12} md={4} lg={3} className="mb-16">
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Miasto" isInvalid={errors.city.error} value={city} onChange={(e) => {setCity(e.target.value)}} />
                                            {errors.city.error && (<Form.Control.Feedback type="invalid">{errors.city.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col> */}
                                    
                                    <div>
                                        <Button variant="primary" type="submit" size="lg" disabled={loading} className="w-100 w-md-auto">
                                            {loading ? <Spinner></Spinner> : "Szukaj ogłoszeń"}
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Col>

                <Col xs={12}>
                {loading ? 
                <div className="d-flex justify-content-center py-24">
                    <Spinner animation="grow" variant="secondary" />
                </div>
                : advertisements.length !== 0 ? 
                <div>
                    {advertisements.map((item) => <div className="d-md-flex rounded-8 shadow-sm overflow-hidden mb-8 p-16" key={item.id}>
                        <div className="w-232 h-140 overflow-hidden rounded-8">
                            <Link to={`/samochody/${item.id}`}>
                                <img src={item.files[0].url} className="w-100 h-auto" />
                            </Link>
                        </div>
                        <div className="ms-md-20 flex-basis-44">
                            <h2 className="h4 mb-8 mt-4"><Link to={"/samochody/" + item.id} className="text-decoration-none text-grey-900">{item.car_brands.name + " " + item.car_models.name + ", " + item.production_year + "r."}</Link></h2>
                            <p className="fs-14 mb-12 text-grey-400">{item.city}, {item.voivodeships.name}</p>
                            <div className="">
                                <div>
                                    <p className="mb-4 text-grey-500 fs-14">Pojemność silnika: {item.engine_capacity}cm3</p>
                                    <p className="mb-4 text-grey-500 fs-14">Rodzaj paliwa: {item.fuel_types.name}</p>
                                    <p className="mb-4 text-grey-500 fs-14">Moc: {item.engine_power}{item.engine_power_unit.toUpperCase()}</p>
                                    <p className="mb-4 text-grey-500 fs-14">Przebieg: {item.mileage}km</p>
                                </div>
                                <div className="mt-8">
                                    <p className="fs-24 fw-bold text-grey-900 mb-0">{item.price} <span className="fs-14">zł</span></p>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    <div className="d-flex justify-content-center mt-48">
                        <ReactPaginate
                            nextLabel="Następne"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="Poprzednie"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="link-previous"
                            previousLinkClassName="link-previous-link"
                            nextClassName="link-next"
                            nextLinkClassName="link-next-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
                : <div className="d-flex justify-content-center py-40">
                    <p>Przepraszamy, brak wyników.</p>
                </div>
                }
                </Col>
            </Row>
        </Container>
    </section>
    );
}

export default connect(
    (state) => ({authentication: state.authentication})
)(Cars);