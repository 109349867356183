import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./TextEditor.scss";

// This component should have been passed 
// description
// changeDescriptionHandler

function TextEditor(props) {
  const reactQuillReference = React.useRef();
  const modules = {
    toolbar: [
      ['bold', 'italic'],
      [{'list': 'ordered'}, {'list': 'bullet'}]
    ],
  };
  const formats = ['bold', 'italic', 'list', 'bullet'];

  function checkTextLength(event){
    const editor = reactQuillReference.current.editor;
    let textLength = editor.getLength();
    
    props.handleDescriptionLength(textLength);
  }
  
  return <ReactQuill theme="snow" ref={reactQuillReference} value={props.description} onKeyDown={checkTextLength} onChange={props.changeDescriptionHandler} modules={modules} formats={formats} className="mb-8" />;
}

export default TextEditor;