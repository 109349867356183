import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {Container, Row, Col, Form, Button, Spinner, Alert} from "react-bootstrap";
import {Link} from "react-router-dom";
import * as yup from "yup";
import Select from 'react-select';
import {supabase} from "./../supabase_client";

function MyAccount(props){
    const [accountTypes, setAccountTypes] = useState([
        {value: "osoba-prywatna", label: "Osoba prywatna"},
        {value: "firma", label: "Firma"}
    ]);
    const [voivodeships, setVoivodeships] = useState([]);
    const [accountType, setAccountType] = useState(accountTypes[0]);
    const [userVoivodeship, setUserVoivodeship] = useState(null);
    const [companyVoivodeship, setCompanyVoivodeship] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [nip, setNip] = useState("");
    const [regon, setRegon] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [userCity, setUserCity] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userRole, setUserRole] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    let [errors1, setErrors1] = useState({
        name: {
            error: false,
            message: ""
        },
        surname: {
            error: false,
            message: ""
        },
        userVoivodeship: {
            error: false,
            message: ""
        },
        userCity: {
            error: false,
            message: ""
        },
        userPhone: {
            error: false,
            message: ""
        }
    });

    let [errors2, setErrors2] = useState({
        companyName: {
            error: false,
            message: ""
        },
        nip: {
            error: false,
            message: ""
        },
        regon: {
            error: false,
            message: ""
        },
        address: {
            error: false,
            message: ""
        },
        postalCode: {
            error: false,
            message: ""
        },
        companyVoivodeship: {
            error: false,
            message: ""
        },
        companyCity: {
            error: false,
            message: ""
        },
        companyPhone: {
            error: false,
            message: ""
        }
    });

    const formSchema1 = yup.object({
        accountType: yup.object({value: yup.string().required(), label: yup.string().required()}).required(),
        name: yup.string().optional(),
        surname: yup.string().optional(),
        userCity: yup.string().optional(),
        userVoivodeship: yup.object({value: yup.string().required(), label: yup.string().required()}).nullable().optional(),
        userPhone: yup.string("Podaj proszę prawidłowy numer telefonu").nullable().optional()
    });

    const formSchema2 = yup.object({
        accountType: yup.object({value: yup.string().required(), label: yup.string().required()}).required(),
        companyName: yup.string("Podaj proszę prawidłową nazwę firmy").optional(),
        nip: yup.number("Podaj proszę prawidłowy numer NIP").transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Podaj proszę prawidłowy numer NIP").integer("Podaj proszę prawidłowy numer NIP").optional(),
        regon: yup.number("Podaj proszę prawidłowy numer REGON").transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Podaj proszę prawidłowy numer REGON").integer("Podaj proszę prawidłowy numer REGON").optional(),
        address: yup.string("Podaj proszę prawidłowy pełny adres.").optional(),
        companyCity: yup.string("Podaj proszę nazwe miejscowości.").optional(), 
        companyPhone: yup.string("Podaj proszę prawidłowy numer telefonu").nullable().optional(),
        companyVoivodeship: yup.object({value: yup.string().required(), label: yup.string().required()}).nullable().optional(),
        postalCode: yup.number("Podaj proszę prawidłowy kod pocztowy (format 00100)").transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Podaj proszę prawidłowy kod pocztowy (format 00100)").integer("Podaj proszę prawidłowy kod pocztowy (format 00100)").optional()
    });

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        let form;
        
        if(accountType.value === "osoba-prywatna"){
            form = formSchema1.validate({
                accountType,
                name,
                surname,
                userVoivodeship,
                userCity,
                userPhone
            }, {abortEarly: false});
        } else if (accountType.value === "firma"){
            form = formSchema2.validate({
                accountType,
                companyName,
                nip,
                regon,
                address,
                postalCode,
                companyCity,
                companyVoivodeship,
                companyPhone
            }, {abortEarly: false});
        }

        form.then((valid) => {
            let newErrors;

            if(accountType.value === "osoba-prywatna"){
                newErrors = {...errors1};
            } else if(accountType.value === "firma"){
                newErrors = {...errors2};
            }

            Object.keys(newErrors).forEach((key) => {
                newErrors[key] = {
                    error: false,
                    message: ""
                }
            });

            if(accountType.value === "osoba-prywatna"){
                setErrors1(newErrors);
            } else if(accountType.value === "firma"){
                setErrors2(newErrors);
            }
        })
        .then((result) => {
            let user_data = {};

            if(accountType.value === "osoba-prywatna"){
                user_data.account_type = "PRIVATE_PERSON";
            } else if(accountType.value === "firma"){
                user_data.account_type = "COMPANY";
            }

            if(accountType.value === "osoba-prywatna"){
                name === "" ? user_data.name = null : user_data.name = name;
                surname === "" ? user_data.surname = null : user_data.surname = surname;
                if(userVoivodeship === null){user_data.voivodeship_id = null} else {user_data.voivodeship_id = userVoivodeship.value}
                if(userCity === ""){user_data.city = null} else {user_data.city = userCity};
                if(userPhone === ""){user_data.phone_number = null} else {user_data.phone_number = userPhone};
                
                user_data.company_name = null;
                user_data.nip = null;
                user_data.regon = null;
                user_data.address = null;
                user_data.postal_code = null;
            }

            if(accountType.value === "firma"){
                if(companyName === ""){user_data.company_name = null} else {user_data.company_name = companyName};
                if(nip === ""){user_data.nip = null} else {user_data.nip = nip};
                if(regon === ""){user_data.regon = null} else {user_data.regon = regon};
                if(address === ""){user_data.address = null} else {user_data.address = address};
                if(postalCode === ""){user_data.postal_code = null} else {user_data.postal_code = postalCode};
                if(companyCity === ""){user_data.city = null} else {user_data.city = companyCity};
                if(companyVoivodeship === null){user_data.voivodeship_id = null;} else {user_data.voivodeship_id = companyVoivodeship.value} 
                if(companyPhone === ""){user_data.phone_number = null} else {user_data.phone_number = companyPhone};

                user_data.name = null;
                user_data.surname = null;
            }

            return supabase.from("users").update(user_data).eq("id", props.authentication.user.user.id);
        })
        .then(({error}) => {
            if(error) throw new Error(error);
            setLoading(false);
            setSuccess(true);
        })
        .catch((error) => {
            let newErrors;

            if(accountType.value === "osoba-prywatna"){
                newErrors = {...errors1};
            } else if(accountType.value === "firma"){
                newErrors = {...errors2};
            }

            Object.keys(newErrors).forEach((key) => {
                let isError = false; 

                for(let i = 0; i < error.inner.length; i++){
                    if(error.inner[i].path === key){
                        newErrors[error.inner[i].path] = {
                            error: true,
                            message: error.inner[i].message
                        }

                        isError = true;
                    }
                }

                if(!isError){
                    newErrors[key] = {
                        error: false,
                        message: ""
                    }
                }
            });

            if(accountType.value === "osoba-prywatna"){
                setErrors1(newErrors);
            } else if (accountType.value === "firma"){
                setErrors2(newErrors);
            }

            setLoading(false);
        });
    }

    function findVoivodeship(voivodeships_options, voivodeship_name){
        for(let i = 0; i < voivodeships_options.length; i++){
            if(voivodeships_options[i].label === voivodeship_name){
                return voivodeships_options[i];
            }
        };
    }

    function getUserData(){
        return supabase.auth.getUser();
    }

    function getVoivodeshipsOptions(){
        return supabase.from("voivodeships").select();
    }

    useEffect(() => {
        let voivodeships;
        
        getVoivodeshipsOptions()
        .then(({data, error}) => {
            if(error) throw new Error(error);
            const new_data = data.map((item) => {
                return {
                    value: item.id, 
                    label: item.name
                }
            });
            
            voivodeships = new_data;
            setVoivodeships(new_data);
            return getUserData();
        })
        .then(({data}) => {
            setUserEmail(data.user.email);
            
            if(data.user.role === "authenticated"){
                setUserRole("Użytkownik");
            }
            
            return supabase.from("users").select(`
            account_type,
            name,
            surname,
            voivodeships (name),
            city,
            phone_number,
            company_name,
            nip,
            regon,
            address,
            postal_code
            `)
            .eq("id", props.authentication.user.user.id);
        })
        .then(({data, error}) => {
            if(error) throw new Error(error);

            let voivodeship = data[0].voivodeships ? findVoivodeship(voivodeships, data[0].voivodeships.name) : null;

            if(data[0].account_type === "PRIVATE_PERSON"){
                setAccountType({value: "osoba-prywatna", label: "Osoba prywatna"});
                
            } else if(data[0].account_type === "COMPANY"){
                setAccountType({value: "firma", label: "Firma"});
            }
            
            if(data[0].name !== null) {setName(data[0].name)} else {setName("")};
            if(data[0].surname !== null) {setSurname(data[0].surname)} else {setSurname("")}
            
            if(data[0].account_type === "PRIVATE_PERSON"){
                setUserVoivodeship(voivodeship);
            } else if(data[0].account_type === "COMPANY"){
                setCompanyVoivodeship(voivodeship);
            }
            
            if(data[0].account_type === "PRIVATE_PERSON"){
                if(data[0].city !== null) {setUserCity(data[0].city)} else {setUserCity("")};
            } else if(data[0].account_type === "COMPANY"){
                if(data[0].city !== null) {setCompanyCity(data[0].city)} else {setCompanyCity("")}
            }
            
            if(data[0].account_type === "PRIVATE_PERSON"){
                if(data[0].phone_number !== null) {setUserPhone(data[0].phone_number.toString())} else {setUserPhone("")}
            } else if(data[0].account_type === "COMPANY"){
                if(data[0].phone_number !== null) {setCompanyPhone(data[0].phone_number.toString())} else {setCompanyPhone("")}
            }
            
            if(data[0].company_name !== null){setCompanyName(data[0].company_name);} else {setCompanyName("");}
            if(data[0].nip !== null){setNip(data[0].nip)} else {setNip("")};
            if(data[0].regon !== null){setRegon(data[0].regon)} else {setRegon("")}
            if(data[0].address !== null){setAddress(data[0].address)} else {setAddress("")}
            if(data[0].postal_code !== null){setPostalCode(data[0].postal_code)} else {setPostalCode("")}
        })
        .catch((error) => {
        })
    }, []);

    return (
        <>
        <section className="mb-48">
            <Container>
                <Row>
                    <Col xs={12} className="mb-40 mt-16">
                        <h1>Ustawienia</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} className="mb-40">
                        <nav>
                            <ul className="list-no-style">
                                <li className="mb-12"><Link to="/konto" className="text-decoration-none fw-bold">Mój profil</Link></li>
                                <li className="mb-12"><Link to="/konto/zmiana-hasla" className="text-decoration-none text-body">Zmiana hasła</Link></li>
                                {/* <li className="mb-12"><Link to="/konto/platnosci" className="text-decoration-none text-body">Płatności</Link></li> */}
                                {/* <li className="mb-12"><Link to="/konto/eksport-danych" className="text-decoration-none text-body">Eksport danych</Link></li> */}
                            </ul>
                        </nav>
                    </Col>
                    <Col xs={12} md={{span: 8, offset: 1}}>
                        <div className="form-section">
                            <h2 className="h3">Ogólne</h2>
                            
                            {success ? 
                            <Alert key={"success"} variant={"success"}>Ustawienia zapisane</Alert>
                            :
                            null
                            }

                            <Form onSubmit={handleSubmit} noValidate>
                                <Row>
                                    <Col xs={12} className="d-flex align-items-center mb-32 mt-8">
                                        <div className="bg-grey-100 rounded-circle w-56 h-56 me-16 d-flex">
                                            {/* <img src={}> */}
                                        </div>
                                        <div>
                                            <h5 className="h6">{userEmail}</h5>
                                            <p className="text-grey-400 fs-14 mb-0">{userRole}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="form_account_type">
                                            <Form.Label>Rodzaj konta</Form.Label>
                                            <Select 
                                                options={accountTypes}
                                                onChange={(value) => {
                                                    setAccountType(value);
                                                }} 
                                                value={accountType}
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false} 
                                                isClearable={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {accountType.value === "osoba-prywatna" ? 
                                    <>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Imię</Form.Label>
                                                <Form.Control type="text" placeholder="Imię" isInvalid={errors1.name.error} value={name} onChange={(e) => {e.preventDefault(); setName(e.target.value);}} />
                                                {errors1.name.error && (<Form.Control.Feedback type="invalid">{errors1.name.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_surname">
                                                <Form.Label>Nazwisko</Form.Label>
                                                <Form.Control type="text" placeholder="Nazwisko" isInvalid={errors1.surname.error} value={surname} onChange={(e) => {e.preventDefault(); setSurname(e.target.value);}} />
                                                {errors1.surname.error && (<Form.Control.Feedback type="invalid">{errors1.surname.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_voivodeship">
                                                <Form.Label>Województwo</Form.Label>
                                                <Select 
                                                    options={voivodeships}
                                                    onChange={(value) => {
                                                        setUserVoivodeship(value);
                                                    }} 
                                                    value={userVoivodeship}
                                                    placeholder="Wybierz" 
                                                    classNames={{
                                                        menu: (state) => "z-index-50"
                                                    }} 
                                                    isSearchable={false} 
                                                    isClearable={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_city">
                                                <Form.Label>Miasto</Form.Label>
                                                <Form.Control type="text" placeholder="Miasto" isInvalid={errors1.userCity.error} value={userCity} onChange={(e) => {e.preventDefault(); setUserCity(e.target.value)}} />
                                                {errors1.userCity.error && (<Form.Control.Feedback type="invalid">{errors1.userCity.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_phone">
                                                <Form.Label>Numer telefonu</Form.Label>
                                                <Form.Control type="number" placeholder="Numer Telefonu" isInvalid={errors1.userPhone.error} value={userPhone} onChange={(e) => {e.preventDefault(); setUserPhone(e.target.value)}} />
                                                {errors1.userPhone.error && (<Form.Control.Feedback type="invalid">{errors1.userPhone.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col xs={12} md={12}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Nazwa firmy</Form.Label>
                                                <Form.Control type="text" placeholder="Nazwa firmy" isInvalid={errors2.companyName.error} value={companyName} onChange={(e) => {e.preventDefault(); setCompanyName(e.target.value)}} />
                                                {errors2.companyName.error && (<Form.Control.Feedback type="invalid">{errors2.companyName.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Numer NIP</Form.Label>
                                                <Form.Control type="number" step={1} placeholder="Numer NIP" isInvalid={errors2.nip.error} value={nip} onChange={(e) => {e.preventDefault(); setNip(e.target.value)}} />
                                                {errors2.nip.error && (<Form.Control.Feedback type="invalid">{errors2.nip.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Numer REGON</Form.Label>
                                                <Form.Control type="number" step={1} placeholder="Numer REGON" isInvalid={errors2.regon.error} value={regon} onChange={(e) => {e.preventDefault(); setRegon(e.target.value)}} />
                                                {errors2.regon.error && (<Form.Control.Feedback type="invalid">{errors2.regon.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Adres siedziby</Form.Label>
                                                <Form.Control type="text" placeholder="Adres siedziby" isInvalid={errors2.address.error} value={address} onChange={(e) => {e.preventDefault(); setAddress(e.target.value)}} />
                                                {errors2.address.error && (<Form.Control.Feedback type="invalid">{errors2.address.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Kod pocztowy</Form.Label>
                                                <Form.Control type="number" step={1} placeholder="Kod pocztowy" isInvalid={errors2.postalCode.error} value={postalCode} onChange={(e) => {e.preventDefault(); setPostalCode(e.target.value)}} />
                                                {errors2.postalCode.error && (<Form.Control.Feedback type="invalid">{errors2.postalCode.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_name">
                                                <Form.Label>Miasto</Form.Label>
                                                <Form.Control type="text" placeholder="Miasto" isInvalid={errors2.companyCity.error} value={companyCity} onChange={(e) => {e.preventDefault(); setCompanyCity(e.target.value)}} />
                                                {errors2.companyCity.error && (<Form.Control.Feedback type="invalid">{errors2.companyCity.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_voivodeship">
                                                <Form.Label>Województwo</Form.Label>
                                                <Select 
                                                    options={voivodeships}
                                                    onChange={(value) => {
                                                        setCompanyVoivodeship(value);
                                                    }} 
                                                    value={companyVoivodeship}
                                                    placeholder="Wybierz" 
                                                    classNames={{
                                                        menu: (state) => "z-index-50"
                                                    }} 
                                                    isSearchable={false} 
                                                    isClearable={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-16" controlId="form_phone">
                                                <Form.Label>Numer telefonu</Form.Label>
                                                <Form.Control type="number" placeholder="Numer Telefonu" isInvalid={errors2.companyPhone.error} value={companyPhone} onChange={(e) => {e.preventDefault(); setCompanyPhone(e.target.value);}} />
                                                {errors2.companyPhone.error && (<Form.Control.Feedback type="invalid">{errors2.companyPhone.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }
                                    
                                    <Col xs={12}>
                                        <p className="fs-14 mt-8 mb-8 text-grey-400">Podanie wszystkich danych <strong>nie jest obowiązkowe</strong>.</p>
                                        <Button variant="primary" type="submit" disabled={loading} className="mt-24">
                                            {loading ? <Spinner></Spinner> : "Zapisz"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default connect(
    (state) => ({authentication: state.authentication}), 
)(MyAccount);