import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./NewAdvertisement.scss";
import NewAdvertisementCar from "./NewAdvertisementCar";
import NewAdvertisementPayment from "./NewAdvertisementPayment";
// import { useForm } from "react-hook-form";
import {connect} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams, useNavigate } from "react-router-dom";
import NotFound from "./NotFound";
import { supabase } from "./../supabase_client";
import { useStripe } from "@stripe/react-stripe-js";

import ImageCar from "bootstrap-icons/icons/car-front-fill.svg";
// import ImageTruck from "bootstrap-icons/icons/truck-front.svg";
// import ImageVan from "./../img/van.png";
// import ImageTrailer from "./../img/trailer.png";
// import ImageTractor from "./../img/tractor.png";
// import ImageMotorcycle from "./../img/motorcycle.png";

function NewAdvertisement(props){
    // const {register, handleSubmit} = useForm();
    const [formStates, setFormStates] = useState({
        page: 1,
        category: null  
    });
    const [formData, setFormData] = useState(null);
    const [plan, setPlan] = useState(null);
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page");
    const category = searchParams.get("category");
    const navigate = useNavigate();
    const stripe = useStripe();

    function handlePlanChange(plan){
        setPlan(plan);
    }

    function handleFormStatesChange(formState){
        setFormStates(formState);
    }

    function handleFormDataChange(data){
        let newData = {};
        Object.assign(newData, formData);
        Object.assign(newData, data);
        setFormData(newData);
    }
    
    function handleFormSubmit(){
        let production_year = formData.productionYear.toString();
        let car_announcement_id;

        // NEW ENTRY CAR ANNOUNCEMENT
        let car_announcement_data = {
            production_year: production_year + "-01-01",
            engine_capacity: formData.engineCapacity,
            engine_power: formData.enginePower,
            engine_power_unit: formData.enginePowerUnit,
            price: formData.price,
            mileage: formData.mileage,
            description: formData.description,
            user_id: props.authentication.user.user.id,
            city: formData.city,
            voivodeship_id: formData.voivodeship,
            fuel_type_id: formData.fuelType,
            car_brand_id: formData.brand,
            car_model_id: formData.model,
            car_generation_id: formData.generation,
            countries_of_origin_id: formData.countryOrigin,
            car_body_type_id: formData.bodyType,
            car_gearbox_type_id: formData.gearbox,
            color_id: formData.color,
            condition_id: formData.condition,
            announcement_package_id: plan,
            car_doors_options_id: formData.doors,
            user_users_id: props.authentication.user.user.id, 
            phone_number: formData.phoneNumber
        };

        if(formData.vin){
            car_announcement_data.vin = formData.vin;
        }

        supabase.from("car_announcements").insert(car_announcement_data).select()
        .then(({data, error}) => {
            if(error) throw new Error(error);
            car_announcement_id = data[0].id;

            // CAR EQUIPMENTS
            let car_equipments = formData.equipment.map((item) => {
                return {
                    car_announcement_id: car_announcement_id,
                    car_equipment_id: item
                }
            });

            return supabase.from("car_announcements_has_car_equipments").insert(car_equipments);
        })
        .then(({error}) => {
            let photosRequests = [];
            if(error) throw new Error(error);

            // UPLOAD FILES
            for (let i = 0; i < formData.photos.length; i++){
                let file_name = uuidv4();

                if(formData.photos[i].type === "image/jpeg"){
                    file_name = file_name + ".jpeg";
                } else if(formData.photos[i].type === "image/gif"){
                    file_name = file_name + ".gif";
                } else if(formData.photos[i].type === "image/png"){
                    file_name = file_name + ".png";
                } else if(formData.photos[i].type === "image/bmp"){
                    file_name = file_name + ".bmp";
                }
                
                let request = supabase.storage.from("car_announcements").upload(file_name, formData.photos[i]);
                photosRequests.push(request);
            }

            return Promise.all(photosRequests);
        })
        .then((result) => {
            let photos_requests = [];

            // insert photos to files table
            for(let i = 0; i < result.length; i++){
                if(result[i].error) throw new Error(result[i].error.code);

                let dot_position = result[i].data.path.indexOf('.');
                let photo_request = supabase.from("files").insert({
                    file_name: result[i].data.path,
                    url: "https://gkphnpkzcwmhhiasezcr.supabase.co/storage/v1/object/public/" + result[i].data.fullPath,
                    file_extension: result[i].data.path.substring(dot_position + 1),
                    car_announcement_id: car_announcement_id
                });
            
                photos_requests.push(photo_request);
            }

            return Promise.all(photos_requests);
        })
        .then((result) => {
            // create stripe session
            // return fetch("http://127.0.0.1:54321/functions/v1/checkout", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": "Bearer " + process.env.REACT_APP_ANON_KEY_TEST,
            //         "user": props.authentication.user.access_token
            //     },
            //     body: JSON.stringify({
            //         plan: 1,
            //         annon_id: car_announcement_id,
            //         email: props.authentication.user.user.email
            //     })
            // })
            // .then((response) => response.json())

            return fetch("https://gkphnpkzcwmhhiasezcr.supabase.co/functions/v1/checkout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + process.env.REACT_APP_ANON_KEY,
                    "user": props.authentication.user.access_token
                },
                body: JSON.stringify({
                    plan: 1,
                    annon_id: car_announcement_id,
                    email: props.authentication.user.user.email
                })
            })
        })
        .then((response) => response.json())
        .then((response) => {
            // Redirect to Stripe Checkout
            stripe.redirectToCheckout({
                sessionId: response.id
            });
        })
        .catch((error) => {
        });
    }

    
    useEffect(() => {

    }, []);

    if(page === "2" && category === "car"){
        return (
            <NewAdvertisementCar 
                formData={formData}
                handleFormDataChange={handleFormDataChange}
                type="add"
            />
        )
    } else if(page === "3" && category === "car"){
        return (
            <NewAdvertisementPayment 
                plan={plan} 
                handlePlanChange={handlePlanChange} 
                handleFormSubmit={handleFormSubmit}
                formData={formData}
                handleFormDataChange={handleFormDataChange}
            />
        )
    } else {
        return (
            <section className="mb-48">
                <Container> 
                    <Row>
                        <Col xs={12} md={8} className="mb-40 mt-16">
                            <h1>Chcę sprzedać</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={8}>
                            <Row>
                                <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); navigate("/nowe-ogloszenie?page=2&category=car");}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageCar} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Samochód osobowy</span>
                                    </button>
                                </Col>
                                {/* <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); setFormStates({...formStates, category: "Motorcycle", page: 2})}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageMotorcycle} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Motocykl</span>
                                    </button>
                                </Col> */}
                                {/* <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); setFormStates({...formStates, category: "Truck", page: 2})}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageTruck} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Samochód ciężarowy</span>
                                    </button>
                                </Col> */}
                                {/* <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); setFormStates({...formStates, category: "Van", page: 2})}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageVan} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Samochód dostawczy</span>
                                    </button>
                                </Col> */}
                                {/* <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); setFormStates({...formStates, category: "Trailer", page: 2})}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageTrailer} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Przyczepa, naczepa</span>
                                    </button>
                                </Col> */}
                                {/* <Col xs={6} sm={4} md={3} className="mb-16">
                                    <button onClick={(e) => {e.preventDefault(); setFormStates({...formStates, category: "Tractor", page: 2})}} className="text-body border border-1 border-secondary p-8 text-center h-160 rounded d-flex flex-column align-items-center justify-content-center w-100 background-none">
                                        <img src={ImageTractor} alt="" className="w-40 h-auto" />
                                        <span className="mt-16">Pojazd rolniczy</span>
                                    </button>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default connect(
    (state) => ({authentication: state.authentication})
)(NewAdvertisement);