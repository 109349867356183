export function split_number(cena) {
    // Konwersja ceny na łańcuch znaków
    let cenaStr = cena.toString();

    // Sprawdzenie, czy cena jest większa niż 3 cyfry
    if (cenaStr.length > 3) {
        // Utworzenie łańcucha z odstępami po każdych trzech cyfrach od końca
        cenaStr = cenaStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return cenaStr;
}