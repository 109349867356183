import { useEffect } from "react";
import "./App.scss";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { supabase } from "./supabase_client";
import { observeAuthChange, authGetSessionThunk } from "./reducers/authentication";

import Homepage from "./pages/Homepage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/NotFound";
import Cars from "./pages/Cars";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpConfirmation from "./pages/SignUpConfirmation.js";
import NewAdvertisement from "./pages/NewAdvertisement.js";
import NewAdvertisementSuccess from "./pages/NewAdvertisementSuccess.js";
import NewAdvertisementCar from "./pages/NewAdvertisementCar.js";
import NewAdvertisementError from "./pages/NewAdvertisementError.js";
import MyAdvertisements from "./pages/MyAdvertisements.js";
import MyAccount from "./pages/MyAccount.js";
import ChangePassword from "./pages/ChangePassword.js";
import MyAccountPayments from "./pages/MyAccountPayments.js";
import MyAccountExportData from "./pages/MyAccountExportData.js";
import EditAdvertisement from "./pages/EditAdvertisement.js";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.js";
import ForgotPasswordChange from "./pages/ForgotPasswordChange/ForgotPasswordChange.js";
import CarDetails from "./pages/CarDetails.js";
import Contact from "./pages/Contact.js";

function App(props) {

  useEffect(() => {
    // props.authGetSessionThunk();
    props.observeAuthChange();
  }, []);

  if(props.authentication.authIsReady){
    return (
      <>
        <Header />
          <main>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/samochody" element={<Cars />} />
              <Route path="/samochody/:id" element={<CarDetails />} />
              <Route path="/logowanie" element={props.authentication.user ? <Navigate to="/" /> : <SignIn />} />
              <Route path="/rejestracja" element={props.authentication.user ? <Navigate to="/" /> : <SignUp />} />
              <Route path="/rejestracja-potwierdz" element={props.authentication.user ? <Navigate to="/" /> : <SignUpConfirmation />} />
              <Route path="/nowe-ogloszenie" element={props.authentication.user ? <NewAdvertisement type="add" /> : <Navigate to="/logowanie" />} />
              <Route path="/nowe-ogloszenie-success" element={props.authentication.user ? <NewAdvertisementSuccess /> : <Navigate to="/" />} />
              <Route path="/nowe-ogloszenie-error" element={props.authentication.user ? <NewAdvertisementError /> : <Navigate to="/" />} />
              <Route path="/moje-ogloszenia" element={props.authentication.user ? <MyAdvertisements /> : <Navigate to="/" />} />
              <Route path="/moje-ogloszenia/edit/:id" element={props.authentication.user ? <EditAdvertisement /> : <Navigate to="/" />} />
              <Route path="/konto" element={props.authentication.user ? <MyAccount /> : <Navigate to="/" />} />
              <Route path="/konto/zmiana-hasla" element={props.authentication.user ? <ChangePassword /> : <Navigate to="/" />} />
              <Route path="/konto/platnosci" element={props.authentication.user ? <MyAccountPayments /> : <Navigate to="/" />} />
              <Route path="/konto/eksport-danych" element={props.authentication.user ? <MyAccountExportData /> : <Navigate to="/" />} />
              <Route path="/zapomnialem-hasla" element={<ForgotPassword />} />
              <Route path="/zapomnialem-hasla-zmiana" element={props.authentication.user ? <ForgotPasswordChange /> : <Navigate to="/" />} />
              <Route path="/skontaktuj-sie" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        <Footer />
      </>
    )
  } else {
    return null;
  }
}

//mapDispatchToProps - function with dispatch as argument
//mapStateToProps - function with state as argument

export default connect(
  (state) => ({authentication: state.authentication}), 
  { observeAuthChange, authGetSessionThunk }
)(App);
