import React, {useState} from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import { object, string, number, date } from 'yup';
import { supabase } from "./../supabase_client.js";

function ChangePassword(){
    // const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({
        password: {
            error: false,
            message: ""
        },
        repeatPassword: {
            error: false,
            message: ""
        }
    });

    // let formSchema = object({
    //     currentPassword: string("Podaj prawidłowe hasło").min(6, "Hasło musi mieć minimum 6 znaków").required("Podaj proszę prawidłowe aktualne hasło"),
    //     password: string()
    //     .min(6, "Hasło musi mieć minimum 6 znaków")
    //     .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .test(
    //         "the-same-password", 
    //         "Hasła muszą być identyczne. Proszę popraw.", 
    //         (value, context) => {
    //             if(value === repeatPassword){
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         })
    //     .required("Hasło jest wymagane"),
    //     repeatPassword: string()
    //     .min(6, "Hasło musi mieć minimum 6 znaków.")
    //     .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
    //     .test(
    //         "the-same-repeatpassword", 
    //         "Hasła muszą być identyczne. Proszę popraw.", 
    //         (value, context) => {
    //             if(value === password){
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         })
    //     .required("Hasło jest wymagane"),
    // });

    let formSchema = object({
        password: string()
        .min(6, "Hasło musi mieć minimum 6 znaków")
        .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .test(
            "the-same-password", 
            "Hasła muszą być identyczne. Proszę popraw.", 
            (value, context) => {
                if(value === repeatPassword){
                    return true;
                } else {
                    return false;
                }
            })
        .required("Hasło jest wymagane"),
        repeatPassword: string()
        .min(6, "Hasło musi mieć minimum 6 znaków.")
        .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .test(
            "the-same-repeatpassword", 
            "Hasła muszą być identyczne. Proszę popraw.", 
            (value, context) => {
                if(value === password){
                    return true;
                } else {
                    return false;
                }
            })
        .required("Hasło jest wymagane"),
    });


    // function handleCurrentPasswordChange(e){
    //     setCurrentPassword(e.target.value);
    // }

    function handlePasswordChange(e){
        setPassword(e.target.value);
    }

    function handleRepeatPasswordChange(e){
        setRepeatPassword(e.target.value);
    }

    function handleSubmit(e){
        e.preventDefault();

        // Form validation
        const form = formSchema.validate({
            password,
            repeatPassword
        }, {abortEarly: false})
        .then((valid) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                newErrors[key] = {
                    error: false,
                    message: ""
                }
            });

            setErrors(newErrors);
        })
        .then((result) => {
            // pobranie hasła, sprawdzenie, zmiana
            return supabase.auth.updateUser({
                password
            })
        })
        .then((result) => {
            // if(error) throw new Error(error);
            setSuccess(true);
        })
        .catch((error) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                let isError = false; 

                for(let i = 0; i < error.inner.length; i++){
                    if(error.inner[i].path === key){
                        newErrors[error.inner[i].path] = {
                            error: true,
                            message: error.inner[i].message
                        }

                        isError = true;
                    }
                }

                if(!isError){
                    newErrors[key] = {
                        error: false,
                        message: ""
                    }
                }
            });

            setErrors(newErrors);
        });
    }

    if(success){
        return (
            <section className="mb-48">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-40 mt-16">
                            <h1>Ustawienia</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3} className="mb-40">
                            <nav>
                                <ul className="list-no-style">
                                    <li className="mb-12"><Link to="/konto" className="text-decoration-none text-body">Mój profil</Link></li>
                                    <li className="mb-12"><Link to="/konto/zmiana-hasla" className="text-decoration-none fw-bold">Zmiana hasła</Link></li>
                                    <li className="mb-12"><Link to="/konto/platnosci" className="text-decoration-none text-body">Płatności</Link></li>
                                    <li className="mb-12"><Link to="/konto/eksport-danych" className="text-decoration-none text-body">Eksport danych</Link></li>
                                </ul>
                            </nav>
                        </Col>
                        <Col xs={12} md={{span: 8, offset: 1}}>
                            <div className="form-section">
                                <h2 className="h3">Zmiana hasła</h2>
                                <p>Hasło zostało zmienione. Dziękujemy.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    } else {
        return (
            <>
            <section className="mb-48">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-40 mt-16">
                            <h1>Ustawienia</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3} className="mb-40">
                            <nav>
                                <ul className="list-no-style">
                                    <li className="mb-12"><Link to="/konto" className="text-decoration-none text-body">Mój profil</Link></li>
                                    <li className="mb-12"><Link to="/konto/zmiana-hasla" className="text-decoration-none fw-bold">Zmiana hasła</Link></li>
                                    {/* <li className="mb-12"><Link to="/konto/platnosci" className="text-decoration-none text-body">Płatności</Link></li> */}
                                    {/* <li className="mb-12"><Link to="/konto/eksport-danych" className="text-decoration-none text-body">Eksport danych</Link></li> */}
                                </ul>
                            </nav>
                        </Col>
                        <Col xs={12} md={{span: 8, offset: 1}}>
                            <div className="form-section">
                                <h2 className="h3">Zmiana hasła</h2>
                                <p className="fs-14 mb-24 text-grey-400">Jeżeli chcesz zmienić hasło, wypełnij poniższy formularz.</p>
                                <Form onSubmit={handleSubmit} noValidate>
                                    <Row>
                                        {/* <Col xs={12}>
                                            <Form.Group className="mb-16" controlId="form_current_password">
                                                <Form.Label>Aktualne hasło</Form.Label>
                                                <Form.Control type="password" placeholder="Aktualne hasło" isInvalid={errors.currentPassword.error} value={currentPassword} onChange={handleCurrentPasswordChange} />
                                                {errors.currentPassword.error && (<Form.Control.Feedback type="invalid">{errors.currentPassword.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col> */}
                                        <Col xs={12}>
                                            <Form.Group className="mb-16" controlId="form_new_password">
                                                <Form.Label>Nowe hasło</Form.Label>
                                                <Form.Control type="password" placeholder="Nowe hasło" isInvalid={errors.password.error} value={password} onChange={handlePasswordChange} />
                                                {errors.password.error && (<Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="mb-16" controlId="form_repeat_new_password">
                                                <Form.Label>Powtórz nowe hasło</Form.Label>
                                                <Form.Control type="password" placeholder="Powtórz nowe hasło" isInvalid={errors.repeatPassword.error} value={repeatPassword} onChange={handleRepeatPasswordChange} />
                                                {errors.repeatPassword.error && (<Form.Control.Feedback type="invalid">{errors.repeatPassword.message}</Form.Control.Feedback>)}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Button type="submit" variant="primary" className="mt-8">Zmień hasło</Button>
                                        </Col>
                                    </Row>
                                </Form> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            </>
        );
    }

   
}

export default ChangePassword;