import React, {useState} from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import "./NewAdvertisementPayment.scss";

function NewAdvertisementPayment(props){
    const [loading, setLoading] = useState(false);
    
    function handleFormSubmit(event){
        event.preventDefault();
        setLoading(true);
        if(props.plan === "632cfffa-f1ef-467a-971e-a9af8957ad99" || props.plan === "8999d263-cb67-43e2-a931-f7a18b6e3887"){
            props.handleFormSubmit();
        } else {
            setLoading(false);
        }
    }

    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} md={8} className="mb-24">
                        <h1>Dodaj ogłoszenie</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-24">
                        <form onSubmit={handleFormSubmit}>
                            <Row>
                                <Col xs={12} md={4}>
                                    <div className={"border border-1 rounded-2 p-16 " + (props.plan === '632cfffa-f1ef-467a-971e-a9af8957ad99' ? "active-plan" : "")}>
                                        <h3>Pakiet 90 dni</h3>
                                        <ul>
                                            <li>Ogłoszenie aktywne przez 90 dni</li>
                                            <li>Ogłoszenie raz wyróżnione</li>
                                        </ul>
                                        <p className="fs-20 fw-semibold">7,99zł</p>
                                        <Button variant="primary" className="w-100" onClick={(e) => props.handlePlanChange('632cfffa-f1ef-467a-971e-a9af8957ad99')} disabled={props.plan === '632cfffa-f1ef-467a-971e-a9af8957ad99' ? true : false}>{props.plan !== '632cfffa-f1ef-467a-971e-a9af8957ad99' ? "Wybierz" : <span><i className="bi bi-check"></i> Wybrany</span>}</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className={"border border-1 rounded-2 p-16 " + (props.plan === '8999d263-cb67-43e2-a931-f7a18b6e3887' ? "active-plan" : "")}>
                                        <h3>Pakiet 120 dni</h3>
                                        <ul>
                                            <li>Ogłoszenie aktywne przez 120 dni</li>
                                            <li>Ogłoszenie dwa razy wyróżnione</li>
                                        </ul>
                                        <p className="fs-20 fw-semibold">9,99zł</p>
                                        <Button variant="primary" className="w-100" onClick={(e) => props.handlePlanChange('8999d263-cb67-43e2-a931-f7a18b6e3887')} disabled={props.plan === '8999d263-cb67-43e2-a931-f7a18b6e3887' ? true : false}>{props.plan !== '8999d263-cb67-43e2-a931-f7a18b6e3887' ? "Wybierz" : <span><i className="bi bi-check"></i> Wybrany</span>}</Button>
                                    </div>
                                </Col>
                                <Col xs={12} className="d-flex justify-content-end my-24">

                                    <Button variant="primary" type="submit" size="lg" disabled={loading}>
                                        {loading ? <Spinner></Spinner> : "Zamów ogłoszenie"}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default NewAdvertisementPayment;