import React from "react";

function NotFound(){
    return (
        <div>
            <p>NotFound</p>
        </div>
    );
}

export default NotFound;