import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { supabase } from "./../supabase_client";

function SignUp(props){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    function emailHandleChange(event){
        setEmail(event.target.value);
    }

    function passwordHandleChange(event){
        setPassword(event.target.value);
    }

    function repeatPasswordHandleChange(event){
        setRepeatPassword(event.target.value);
    }

    function checkPasswords(password, repeatPassword){
        if(password === repeatPassword){
            return true;
        } else {
            return false;
        }
    }

    function createErrorMessage(errorCode){
        switch(errorCode){
            case "auth/weak-password":
                setError("Podane hasło jest zbyt słabe. Podaj hasło zawierające przynajmniej 6 znaków.");
                break;
            case "auth/email-already-in-use":
                setError("Podany adres email jest już w użyciu.");
                break;
            default:
                setError("Wystąpił błąd.")
                return;
        }
    }

    function createUserAccount(event){
        event.preventDefault();
        setLoading(true);

        if(!checkPasswords(password, repeatPassword)){
            setError("Podane hasła do siebie nie pasują.");
            setLoading(false);
            return;
        }
        
        supabase.auth.signUp({
            email: email, 
            password: password,
            options: {
                emailRedirectTo: process.env.REACT_APP_APP_URL
            }
        })
        .then(({data, error}) => {
            if(error) return Promise.reject(error);

            if(data.user){
                // Create user row in database
                return supabase.from("users").insert({
                    id: data.user.id,
                    email: email
                });
            } else {
                setError(error.message);
                setLoading(false);
                throw new Error();
            }   
        })
        .then((result) => {
            navigate("/rejestracja-potwierdz?email=" + email);
        })
        .catch((error) => {
            if(error.name === "AuthWeakPasswordError"){
                setError("Przepraszam, hasło musi zawierać co najmniej jedną małą literę, dużą literę i znak specjalny.")
            } else {
                setError(error.message);
            }
            
            setLoading(false);
        })
    }

    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} sm={{span: 6, offset: 3}}>
                        <h2 className="mb-16">Zarejestruj się</h2>

                        {error ? 
                        <div className="mt-4">
                            <Alert variant="danger">{error}</Alert>
                        </div>  
                        :
                        null
                        }

                        <Form onSubmit={createUserAccount} className="mb-40">
                            <Form.Group className="mb-8" controlId="email">
                                <Form.Label>Adres email</Form.Label>
                                <Form.Control type="email" placeholder="Adres email" value={email} onChange={emailHandleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-8" controlId="password">
                                <Form.Label>Hasło</Form.Label>
                                <Form.Control type="password" placeholder="Hasło" value={password} onChange={passwordHandleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-8" controlId="repeatPassword">
                                <Form.Label>Powtórz hasło</Form.Label>
                                <Form.Control type="password" placeholder="Powtórz hasło" value={repeatPassword} onChange={repeatPasswordHandleChange} required />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading} className="w-100 mt-16">Zarejestuj się</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SignUp;