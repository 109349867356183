import React, {useState, useEffect} from "react";
import { Card, Button, Container, Row, Col, Tab, Tabs, Form, Spinner, Modal } from "react-bootstrap";
import Select from 'react-select';
import {Link, useNavigate} from "react-router-dom";
import { supabase } from "./../supabase_client";
import blogThumbnail from "./../assets/blog-thumbnail.jpg";

function Homepage(){
    const [carBrands, setCarBrands] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [carGenerations, setCarGenerations] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [carAnnouncementsNewest, setCarAnnouncementsNewest] = useState([]);
    const [loadingCarAnnouncementsNewest, setLoadingCarAnnouncementsNewest] = useState(false);
    const [carAnnouncements20, setCarAnnouncements20] = useState([]);
    const [loadingCarAnnouncements20, setLoadingCarAnnouncements20] = useState(false);
    const [carAnnouncements40, setCarAnnouncements40] = useState([]);
    const [loadingCarAnnouncements40, setLoadingCarAnnouncements40] = useState(false);
    const [carAnnouncements80, setCarAnnouncements80] = useState([]);
    const [loadingCarAnnouncements80, setLoadingCarAnnouncements80] = useState(false);
    const [carAnnouncementsFrom80, setCarAnnouncementsFrom80] = useState([]);
    const [loadingCarAnnouncementsFrom80, setLoadingCarAnnouncementsFrom80] = useState(false);
    const [searchBrand, setSearchBrand] = useState(null);
    const [searchModel, setSearchModel] = useState(null);
    const [searchGeneration, setSearchGeneration] = useState(null);
    const [searchFuelType, setSearchFuelType] = useState(null);
    const [searchPriceFrom, setSearchPriceFrom] = useState("");
    const [searchPriceTo, setSearchPriceTo] = useState("");
    const [carAnnouncementsAmount, setCarAnnouncementsAmount] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleCloseModal = () => {
        localStorage.setItem("info-homepage", "false");
        setShowModal(false);
    };
    const handleShowModal = () => setShowModal(true);

    function getCarBrands(){
        return supabase.from("car_brands").select()
        .then(({data, error}) => {
            if(error) throw new Error(error);

            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarBrands(new_data);
        })
        .catch((error) => {
            
        });
    }

    function getCarModels(brand_id){
        supabase.from("car_models").select().eq("car_brand_id", brand_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarModels(new_data);
        })
        .catch((error) => {

        });
    }

    function getCarGenerations(model_id){
        supabase.from("car_generations").select().eq("car_model_id", model_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarGenerations(new_data);
        })
        .catch((error) => {

        })
    }

    function getCarFuelTypes(){
        return supabase.from("fuel_types").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            
            setFuelTypes(new_data);
        })
        .catch((error) => {

        });
    }

    function getNewestCarAnnouncements(){
        return supabase.from("car_announcements")
        .select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage, 
            fuel_types (name), 
            car_brands (name),
            car_models (name),
            car_generations (name),
            car_gearbox_types (name),
            files (url)
        `)
        .eq("status", "COMPLETED")
        .order("created_at", {ascending: false})
        .limit(8)
        .then(({data, error}) => {
            setLoadingCarAnnouncementsNewest(false);
            setCarAnnouncementsNewest(data);
        });
    }

    function getCarsTo20(){
        return supabase.from("car_announcements")
        .select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage, 
            fuel_types (name), 
            car_brands (name),
            car_models (name),
            car_generations (name),
            car_gearbox_types (name),
            files (url)
        `)
        .eq("status", "COMPLETED")
        .lte("price", 20000)
        .order("created_at", {ascending: false})
        .limit(8)
        .then(({data, error}) => {
            setLoadingCarAnnouncements20(false);
            setCarAnnouncements20(data);
        });
    }

    function getCarsTo40(){
        return supabase.from("car_announcements")
        .select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage, 
            fuel_types (name), 
            car_brands (name),
            car_models (name),
            car_generations (name),
            car_gearbox_types (name),
            files (url)
        `)
        .eq("status", "COMPLETED")
        .gt("price", 20000)
        .lte("price", 40000)
        .order("created_at", {ascending: false})
        .limit(8)
        .then(({data, error}) => {
            setLoadingCarAnnouncements40(false);
            setCarAnnouncements40(data);
        });
    }

    function getCarsTo80(){
        return supabase.from("car_announcements")
        .select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage, 
            fuel_types (name), 
            car_brands (name),
            car_models (name),
            car_generations (name),
            car_gearbox_types (name),
            files (url)
        `)
        .eq("status", "COMPLETED")
        .gt("price", 40000)
        .lte("price", 80000)
        .order("created_at", {ascending: false})
        .limit(8)
        .then(({data, error}) => {
            setLoadingCarAnnouncements80(false);
            setCarAnnouncements80(data);
        });
    }

    function getCarsFrom80(){
        return supabase.from("car_announcements")
        .select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage, 
            fuel_types (name), 
            car_brands (name),
            car_models (name),
            car_generations (name),
            car_gearbox_types (name),
            files (url)
        `)
        .eq("status", "COMPLETED")
        .gte("price", 80000)
        .order("created_at", {ascending: false})
        .limit(8)
        .then(({data, error}) => {
            setLoadingCarAnnouncementsFrom80(false);
            setCarAnnouncementsFrom80(data);
        });
    }

    function handleSubmit(e){
        e.preventDefault();
        let url = "/samochody?";

        if(searchBrand){
            url = url + "brand=" + searchBrand.value + "&";
        }

        if(searchModel){
            url = url + "model=" + searchModel.value + "&";
        }

        if(searchGeneration){
            url = url + "generation=" + searchGeneration.value + "&";
        }

        if(searchFuelType){
            url = url + "fuel_type=" + searchFuelType.value + "&";
        }

        if(searchPriceFrom){
            url = url + "price_from=" + searchPriceFrom + "&";
        }

        if(searchPriceTo){
            url = url + "price_to=" + searchPriceTo;
        }

        navigate(url);
    }

    useEffect(() => {
        getCarBrands();
        getCarFuelTypes();

        setLoadingCarAnnouncementsNewest(true);
        getNewestCarAnnouncements()
        .catch((error) => {

        });

        setLoadingCarAnnouncements20(true);
        getCarsTo20()
        .catch((error) => {

        });

        setLoadingCarAnnouncements40(true);
        getCarsTo40()
        .catch((error) => {

        })

        setLoadingCarAnnouncements80(true);
        getCarsTo80()
        .catch((error) => {

        })

        setLoadingCarAnnouncementsFrom80(true);
        getCarsFrom80()
        .catch((error) => {

        })

        let homepage_modal = localStorage.getItem("info-homepage");
        if(!homepage_modal){
            setShowModal(true);
        }
    }, [])

    return (
        <>
            <section className="mb-16">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="px-32 py-32 mb-4 bg-light rounded-32">
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <h1 className="display-5 fw-bold">Ogłoszenia motoryzacyjne</h1>
                                        <p className="col-md-8 fs-4">Wyszukaj samochód używany lub nowy!</p>

                                        <div className="bg-white rounded-4 p-16 w-100 w-lg-80 shadow-grey ">
                                            <div>
                                                <Form onSubmit={handleSubmit}>
                                                    <div>
                                                        <Row>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Select 
                                                                        options={carBrands}
                                                                        onChange={(value) => {
                                                                            setSearchBrand(value);
                                                                            setSearchModel(null); // reset model select value
                                                                            getCarModels(value.value);
                                                                            setSearchGeneration(null);
                                                                            setCarGenerations([]);
                                                                        }} 
                                                                        value={searchBrand}
                                                                        placeholder="Marka" 
                                                                        classNames={{
                                                                            menu: (state) => "z-index-50"
                                                                        }} 
                                                                        isSearchable={true}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Select 
                                                                        options={carModels}
                                                                        onChange={(value) => {
                                                                            setSearchModel(value); // set model value
                                                                            setSearchGeneration(null); // reset generation select value
                                                                            getCarGenerations(value.value);
                                                                        }} 
                                                                        value={searchModel}
                                                                        placeholder="Model" 
                                                                        isDisabled={carModels.length === 0 ? true : false}
                                                                        classNames={{
                                                                            menu: (state) => "z-index-50"
                                                                        }} 
                                                                        isSearchable={true}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Select 
                                                                        options={carGenerations}
                                                                        onChange={(value) => {
                                                                            setSearchGeneration(value);
                                                                        }} 
                                                                        value={searchGeneration}
                                                                        placeholder="Generacja" 
                                                                        isDisabled={carGenerations.length === 0 ? true : false }
                                                                        classNames={{
                                                                            menu: (state) => "z-index-50"
                                                                        }} 
                                                                        isSearchable={true}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Select 
                                                                        options={fuelTypes}
                                                                        onChange={(value) => {
                                                                            setSearchFuelType(value);
                                                                        }} 
                                                                        value={searchFuelType}
                                                                        placeholder="Rodzaj paliwa" 
                                                                        classNames={{
                                                                            menu: (state) => "z-index-50"
                                                                        }} 
                                                                        isSearchable={true}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Form.Control type="number" placeholder="Cena od" value={searchPriceFrom} onChange={(e) => {e.preventDefault(); setSearchPriceFrom(e.target.value);}} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-16">
                                                                <Form.Group>
                                                                    <Form.Control type="number" placeholder="Cena do" value={searchPriceTo} onChange={(e) => {e.preventDefault(); setSearchPriceTo(e.target.value);}} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div>
                                                        <Button type="submit" variant="primary" size="lg" className="w-100">Szukaj ogłoszeń <span className="fs-16">{carAnnouncementsAmount ? `(${carAnnouncementsAmount})` : null}</span></Button>
                                                    </div>
                                                </Form>


                                                {/* <Tabs defaultActiveKey="osobowe" id="searchForm" className="mb-16" fill>
                                                    <Tab eventKey="osobowe" title="Osobowe">
                                                        
                                                    </Tab> */}
                                                    {/* <Tab eventKey="motyckle" title="Motocykle">
                                                        <p>W trakcie wdrozenia.</p>
                                                    </Tab>
                                                    <Tab eventKey="ciezarowe" title="Ciężarowe">
                                                        <p>Ciężarowe</p>
                                                    </Tab>
                                                    <Tab eventKey="dostawcze" title="Dostawcze">
                                                        <p>Dostawcze</p>
                                                    </Tab>
                                                    <Tab eventKey="pojazdy-rolnicze" title="Pojazdy rolnicze">
                                                        <p>Pojazdy rolnicze</p>
                                                    </Tab>
                                                    <Tab eventKey="przyczepy-naczepy" title="Przyczepy, naczepy">
                                                        <p>Przyczepy, naczepy</p>
                                                    </Tab> */}
                                                {/* </Tabs> */}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-24">Najnowsze ogłoszenia</h2>
                            <Link to="/samochody" className="link-normal">Pokaż więcej <i className="bi bi-arrow-right ms-8"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        {loadingCarAnnouncementsNewest ? 
                        <div className="h-240 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="w-28 h-28" />
                        </div>
                        :
                        carAnnouncementsNewest.map((element) => {
                            return (
                                <Col xs={6} md={4} lg={3} key={element.id}>
                                    <article>
                                        <Link to={`/samochody/${element.id}`}>
                                            <img src={element.files[0].url} alt="" className="w-100 h-180 rounded-12 object-fit-cover" />
                                        </Link>
                                        <div className="py-16">
                                            <h3 className="h5"><Link to={`/samochody/${element.id}`} className="text-decoration-none text-dark">{`${element.car_brands.name} ${element.car_models.name}`} </Link></h3>
                                            <p className="fs-14 mb-0">Przebieg: {element.mileage + element.engine_power_unit}</p>
                                            <p className="fs-14 mb-0">Pojemność: {element.engine_capacity}cm3</p>
                                            <p className="fs-14 mb-0">Rodzaj paliwa: {element.fuel_types.name}</p>
                                            <p className="fs-20 fw-bold mt-8 text-dark mb-0">{element.price} <span className="fs-16">zł</span></p>
                                        </div>
                                    </article>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </section>

            <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-24">Samochody do 20 tys. zł</h2>
                            <Link to="/samochody" className="link-normal">Pokaż więcej <i className="bi bi-arrow-right ms-8"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        {loadingCarAnnouncements20 ? 
                        <div className="h-240 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="w-28 h-28" />
                        </div>
                        :
                        carAnnouncements20.map((element) => {
                            return (
                                <Col xs={6} md={4} lg={3} key={element.id}>
                                    <article>
                                        <Link to={`/samochody/${element.id}`}>
                                            <img src={element.files[0].url} alt="" className="w-100 h-180 rounded-12 object-fit-cover" />
                                        </Link>
                                        <div className="py-16">
                                            <h3 className="h5"><Link to={`/samochody/${element.id}`} className="text-decoration-none text-dark">{`${element.car_brands.name} ${element.car_models.name}`} </Link></h3>
                                            <p className="fs-14 mb-0">Przebieg: {element.mileage + element.engine_power_unit}</p>
                                            <p className="fs-14 mb-0">Pojemność: {element.engine_capacity}cm3</p>
                                            <p className="fs-14 mb-0">Rodzaj paliwa: {element.fuel_types.name}</p>
                                            <p className="fs-20 fw-bold mt-8 text-dark">{element.price} <span className="fs-16">zł</span></p>
                                        </div>
                                    </article>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </section>

            <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-24">Samochody do 40 tys. zł</h2>
                            <Link to="/samochody" className="link-normal">Pokaż więcej <i className="bi bi-arrow-right ms-8"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        {loadingCarAnnouncements40 ? 
                        <div className="h-240 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="w-28 h-28" />
                        </div>
                        :
                        carAnnouncements40.map((element) => {
                            return (
                                <Col xs={6} md={4} lg={3} key={element.id}>
                                    <article>
                                        <Link to={`/samochody/${element.id}`}>
                                            <img src={element.files[0].url} alt="" className="w-100 h-180 rounded-12 object-fit-cover" />
                                        </Link>
                                        <div className="py-16">
                                            <h3 className="h5"><Link to={`/samochody/${element.id}`} className="text-decoration-none text-dark">{`${element.car_brands.name} ${element.car_models.name}`} </Link></h3>
                                            <p className="fs-14 mb-0">Przebieg: {element.mileage + element.engine_power_unit}</p>
                                            <p className="fs-14 mb-0">Pojemność: {element.engine_capacity}cm3</p>
                                            <p className="fs-14 mb-0">Rodzaj paliwa: {element.fuel_types.name}</p>
                                            <p className="fs-20 fw-bold mt-8 text-dark">{element.price} <span className="fs-16">zł</span></p>
                                        </div>
                                    </article>
                                </Col>
                            )})
                        }
                    </Row>
                </Container>
            </section>

            <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-24">Samochody do 80 tys. zł</h2>
                            <Link to="/samochody" className="link-normal">Pokaż więcej <i className="bi bi-arrow-right ms-8"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        {loadingCarAnnouncements80 ? 
                        <div className="h-240 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="w-28 h-28" />
                        </div>
                        :
                        carAnnouncements80.map((element) => {
                            return (
                                <Col xs={6} md={4} lg={3} key={element.id}>
                                    <article>
                                        <Link to={`/samochody/${element.id}`}>
                                            <img src={element.files[0].url} alt="" className="w-100 h-180 rounded-12 object-fit-cover" />
                                        </Link>
                                        <div className="py-16">
                                            <h3 className="h5"><Link to={`/samochody/${element.id}`} className="text-decoration-none text-dark">{`${element.car_brands.name} ${element.car_models.name}`} </Link></h3>
                                            <p className="fs-14 mb-0">Przebieg: {element.mileage + element.engine_power_unit}</p>
                                            <p className="fs-14 mb-0">Pojemność: {element.engine_capacity}cm3</p>
                                            <p className="fs-14 mb-0">Rodzaj paliwa: {element.fuel_types.name}</p>
                                            <p className="fs-20 fw-bold mt-8 text-dark">{element.price} <span className="fs-16">zł</span></p>
                                        </div>
                                    </article>
                                </Col>
                            )})
                        }
                    </Row>
                </Container>
            </section>

            <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-center">
                            <h2 className="mb-24">Samochody od 80 tys. zł</h2>
                            <Link to="/samochody" className="link-normal">Pokaż więcej <i className="bi bi-arrow-right ms-8"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        {loadingCarAnnouncementsFrom80 ? 
                        <div className="h-240 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="secondary" className="w-28 h-28" />
                        </div>
                        :
                        carAnnouncementsFrom80.map((element) => {
                            return (
                                <Col xs={6} md={4} lg={3} key={element.id}>
                                    <article>
                                        <Link to={`/samochody/${element.id}`}>
                                            <img src={element.files[0].url} alt="" className="w-100 h-180 rounded-12 object-fit-cover" />
                                        </Link>
                                        <div className="py-16">
                                            <h3 className="h5"><Link to={`/samochody/${element.id}`} className="text-decoration-none text-dark">{`${element.car_brands.name} ${element.car_models.name}`} </Link></h3>
                                            <p className="fs-14 mb-0">Przebieg: {element.mileage + element.engine_power_unit}</p>
                                            <p className="fs-14 mb-0">Pojemność: {element.engine_capacity}cm3</p>
                                            <p className="fs-14 mb-0">Rodzaj paliwa: {element.fuel_types.name}</p>
                                            <p className="fs-20 fw-bold mt-8 text-dark">{element.price} <span className="fs-16">zł</span></p>
                                        </div>
                                    </article>
                                </Col>
                            )})
                        }
                    </Row>
                </Container>
            </section>

            {/* <section className="py-32">
                <Container>
                    <Row>
                        <Col xs={12} className="bg-light rounded-16">
                            <div className="py-40 px-24">
                                <h3 className="mb-16 h2">Kup używany samochód - baner</h3>
                                <p className="mb-0">Skorzystaj z super możliwości!</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className="py-16">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className="mb-24">Blog</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className="mb-16">
                            <Link to="/">
                                <img src={blogThumbnail} alt="" className="w-100 rounded-16 w-100 h-220 object-fit-cover mb-8" />
                            </Link>
                            <div className="mt-16">
                                <h3 className="h4">Otwarcie aplikacji Motoso</h3>
                                <p>Informujemy że aplikacja Motoso rozpoczęła z dniem 1 Maja 2024 roku swoją działalność. Przeczytaj artykuł i zapoznaj się z funkcjami jakie aplikacja Motoso posiada. Codziennie stajemy się lepsi dla Ciebie!</p>
                                <Link to="" className="link-normal">Przeczytaj artykuł</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className="py-32">
                <Container>
                    <Row>
                        <Col xs={12} className="bg-light rounded-16">
                            <div className="py-40 px-24">
                                <Row>
                                    <Col xs={12} md={8}>
                                        <h3 className="mb-16 h2">Newsletter</h3>
                                        <p className="mb-16">Zapisz się do newslettera i otrzymuj powiadomienia o nowych artykułach w naszym serwisie.</p>

                                        <Form className="d-flex align-items-start">
                                            <Form.Group className="mb-16 flex-grow-1" controlId="newsletter-email">
                                                <Form.Control type="email" placeholder="Adres email" />
                                            </Form.Group>
                                            
                                            <Button className="ms-16" variant="primary" type="submit">Zapisz się</Button>
                                        </Form>

                                        <p className="fs-12 text-secondary mb-0">Zapisując się, wyrażasz zgodę na otrzymywanie newslettera i akceptujesz nasz <Link to="/">Regulamin</Link>.</p>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="h4">Aplikacja Motozo wystartowała! </h2>
                    <p>Zachęcamy do wystawiania ogłoszeń. Codziennie pracujemy nad nowymi funkcjonalnościami. Wykorzystujemy najnowsze technologie, aby aplikacja była szybka i bezpieczna.</p>
                    <p>Zapraszamy do założenia <strong>darmowego konta!</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>Dobrze</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Homepage;