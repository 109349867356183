import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, InputGroup, Button, Spinner, Alert } from "react-bootstrap";
import * as yup from "yup";
import Select from 'react-select';
import { supabase } from "../supabase_client";
import { useNavigate, useParams } from "react-router-dom";
import {connect} from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import DropzoneImages from "../components/DropzoneImages/DropzoneImages";
import TextEditor from "../components/TextEditor/TextEditor";

function EditAdvertisement(props){
    const [carEquipments, setCarEquipments] = useState([]);
    const [carEquipmentsAmount, setCarEquipmentsAmount] = useState(null);
    const [carBrands, setCarBrands] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [carGenerations, setCarGenerations] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [countriesOfOrigin, setCountriesOfOrigin] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [gearboxes, setGearboxes] = useState([]);
    const [colors, setColors] = useState([]);
    const [voivodeships, setVoivodeships] = useState([]);
    const [doors, setDoors] = useState([]);
    const [errors, setErrors] = useState({
        brand: {
            error: false,
            message: ""
        },
        model: {
            error: false,
            message: ""
        },
        generation: {
            error: false,
            message: ""
        },
        bodyType: {
            error: false,
            message: ""
        },
        photos: {
            error: false,
            message: ""
        },
        productionYear: {
            error: false,
            message: ""
        },
        engineCapacity: {
            error: false,
            message: ""
        },
        enginePower: {
            error: false,
            message: ""
        },
        enginePowerUnit: {
            error: false,
            message: ""
        },
        fuelType: {
            error: false,
            message: ""
        },
        countryOfOrigin: {
            error: false,
            message: ""
        },
        condition: {
            error: false,
            message: ""
        },
        mileage: {
            error: false,
            message: ""
        },
        gearbox: {
            error: false,
            message: ""
        },
        color: {
            error: false,
            message: ""
        },
        door: {
            error: false,
            message: ""
        },
        vin: {
            error: false,
            message: ""
        },
        price: {
            error: false,
            message: ""
        },
        description: {
            error: false,
            message: ""
        },
        voivodeship: {
            error: false,
            message: ""
        },
        city: {
            error: false,
            message: ""
        },
        phoneNumber: {
            error: false,
            message: ""
        }
    });

    const [model, setModel] = useState(null);
    const [brand, setBrand] = useState(null);
    const [generation, setGeneration] = useState(null);
    const [bodyType, setBodyType] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [countryOfOrigin, setCountryOfOrigin] = useState(null);
    const [condition, setCondition] = useState(null);
    const [gearbox, setGearbox] = useState(null);
    const [color, setColor] = useState(null);
    const [door, setDoor] = useState(null);
    const [voivodeship, setVoivodeship] = useState(null);
    const [productionYear, setProductionYear] = useState("");
    const [engineCapacity, setEngineCapacity] = useState("");
    const [enginePower, setEnginePower] = useState("");
    const [enginePowerUnit, setEnginePowerUnit] = useState("km");
    const [mileage, setMileage] = useState("");
    const [vin, setVin] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [city, setCity] = useState("");
    const [photos, setPhotos] = useState([]);
    const [photosFilesServer, setPhotosFilesServer] = useState([]);
    const [photosFiles, setPhotosFiles] = useState([]);
    const [checkedCarEquipments, setCheckedCarEquipments] = useState(new Array(100).fill(false));
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [descriptionLength, setDescriptionLength] = useState(0);
    const [announcementData, setAnnouncementData] = useState(null);
    const carBrandSelectRef = useRef();
    const carModelSelectRef = useRef();
    const carGenerationSelectRef = useRef();
    const cityInputRef = useRef();
    const navigate = useNavigate();
    const { id } = useParams();

    const schema = yup.object({
        brand: yup.string().test(
            "proper-brand-id", 
            "Proszę wybierz markę pojazdu", 
            (value, context) => {
                let valid = false;
                carBrands.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz markę pojazdu."),
        model: yup.string().test(
            "proper-model-id", 
            "Proszę wybierz model pojazdu", 
            (value, context) => {
                let valid = false;
                carModels.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz model pojazdu."),
        generation: yup.string().test(
            "proper-generation-id", 
            "Proszę wybierz generacje pojazdu",
            (value, context) => {
                let valid = false;

                carGenerations.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });

                if(carGenerations.length === 0){
                    return true;
                }
                
                return valid;
            })
            .nullable()
            .optional(),
        bodyType: yup.string().test(
            "proper-bodyType", 
            "Proszę wybierz typ nadwozia", 
            (value, context) => {
                let valid = false;
                bodyTypes.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz typ nadwozia pojazdu."),
        photos: yup.array().min(1, "Proszę dodaj zdjęcie."),
        productionYear: yup.number().transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Wprowadź poprawny rok produkcji.").integer("Wprowadź poprawny rok produkcji.").min(1800, "Rok produkcji jest zbyt niski. Wprowadź poprawny.").max(new Date().getFullYear(), "Zbyt wysoki rok produkcji. Wprowadź poprawny.").required("Proszę wprowadź rok produkcji."),
        engineCapacity: yup.number().transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Wprowadź poprawną pojemność silnika.").integer("Wprowadź poprawną pojemność silnika.").required("Proszę wprowadź pojemność silnika."),
        enginePower: yup.number().transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Wprowadź poprawną moc silnika.").integer("Wprowadź poprawną moc silnika.").required("Proszę wprowadź moc silnika."),
        enginePowerUnit: yup.string("Wprowadź poprawną jednostkę mocy silnika.").required("Proszę wybierz jednostkę mocy silnika."),
        fuelType: yup.string().required("Proszę wybierz rodzaj paliwa."),
        countryOfOrigin: yup.string().required("Proszę wybierz kraj pochodzenia pojazdu."),
        condition: yup.string().required("Proszę wybierz stan pojazdu."),
        mileage: yup.number().transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Wprowadź poprawny przebieg pojazdu.").integer("Wprowadź poprawny przebieg pojazdu.").required("Proszę wprowadź przebieg pojazdu."),
        gearbox: yup.string().required("Proszę wybierz rodzaj skrzyni biegów."),
        color: yup.string().required("Proszę wybierz kolor pojazdu."),
        door: yup.string().required("Proszę wybierz liczbe drzwi."),
        vin: yup.lazy((value) => value === "" ? yup.string() : yup.string().min(17, "Podany numer VIN jest zbyt krótki.").max(17, "Podany numer VIN jest za długi.")),
        price: yup.number().transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        }).nullable().positive("Proszę podaj poprawną cenę pojazdu.").required("Proszę podaj cenę pojazdu."),
        description: yup.string().test(
            "description_length", 
            "Opis jest zbyt krótki. Musi posiadać minimum 50 znaków.", 
            (value, context) => {
                return (description.length < 50 ? false : true)
            })
            .required("Proszę wpisz opis."),
        voivodeship: yup.string().required("Proszę wybierz województwo."),
        city: yup.string().required("Proszę wpisz miasto."),
        equipment: yup.array(), 
        phoneNumber: yup.number()
        .transform((value, originalValue) => {
            if(originalValue === ""){
                return null
            } else {
                return Number(originalValue);
            }
        })
        .test(
            "phone_number_valid", 
            "Numer jest nieprawidłowy", 
            (value, context) => {
                let phone_number = value.toString();
                return (phone_number.length === 10 || phone_number.length === 9 ? true : false)
            })
        .required("Proszę podaj numer telefonu")
    });


    function getCarBrands(){
        return supabase.from("car_brands").select()
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarBrands(new_data);
        });
    }

    function getCarModels(brand_id){
        return supabase.from("car_models").select().eq("car_brand_id", brand_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            
            setCarModels(new_data);
        });
    }

    function getCarGenerations(model_id){
        return supabase.from("car_generations").select().eq("car_model_id", model_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarGenerations(new_data);
        })
    }

    function getBodyTypes(){
        return supabase.from("car_body_types").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            
            setBodyTypes(new_data);
        });
    }

    function getCarFuelTypes(){
        return supabase.from("fuel_types").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setFuelTypes(new_data);
        });
    }

    function getCountriesOfOrigin(){
        return supabase.from("countries_of_origin").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCountriesOfOrigin(new_data);
        });
    }

    function getConditions(){
        return supabase.from("conditions").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setConditions(new_data);
        });
    }

    function getGearboxTypes(){
        return supabase.from("car_gearbox_types").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setGearboxes(new_data);
        })
    }

    function getColors(){
        return supabase.from("colors").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setColors(new_data);
        });
    }

    function getDoorsOptions(){
        return supabase.from("car_doors_options").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            });

            setDoors(new_data);
        });
    }

    function getVoivodeshipsOptions(){
        return supabase.from("voivodeships").select()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            });

            setVoivodeships(new_data);
        });
    }

    function getCarEquipmentsOptions(){
        return supabase.from("car_equipments").select();
    }

    function getCarEquipmentsAmount(){
        return supabase.from("car_equipments").select('*', { count: 'exact', head: true });
    }

    function handleChangeCarEquipment(position){
        let new_car_equipments = [...checkedCarEquipments];
        new_car_equipments[position] = !new_car_equipments[position];
        setCheckedCarEquipments(new_car_equipments);
    }

    function howImagesAdd(){
        // let photos_add = [];
        
        // for(let i = 0; i < photosFiles.length; i++){
        //     let helper = 0;

        //     for(let j = 0; j < photos.length; j++){
        //         if(photosFiles[i].name === photos[j].file_name){
        //             helper++;
        //             break;
        //         }
        //     }

        //     if(helper === 0) photos_add.push(photosFiles[i]);
        // }
        
        // return photos_add;

        return photosFiles.filter(photoFile => !photos.some(photo => photo.file_name === photoFile.name));
    }

    function getDeletedPhotos() {
        let photos_delete = [];

        return photos.filter(photo => !photosFiles.some(photoFile => photo.file_name === photoFile.name));
    }

    function submitForm(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);

        let brand_validate = brand ? brand.value : null;
        let model_validate = model ? model.value : null;
        let generation_validate = generation ? generation.value : null;
        let bodyType_validate = bodyType ? bodyType.value : null;
        let fuelType_validate = fuelType ? fuelType.value : null;

        schema.validate({
            brand: brand_validate, 
            model: model_validate, 
            generation: generation_validate, 
            bodyType: bodyType_validate, 
            photos: photosFiles, 
            productionYear, 
            engineCapacity, 
            enginePower, 
            enginePowerUnit, 
            fuelType: fuelType_validate, 
            countryOfOrigin: countryOfOrigin.value, 
            condition: condition.value, 
            mileage, 
            gearbox: gearbox.value, 
            color: color.value, 
            door: door.value, 
            vin, 
            price, 
            description, 
            voivodeship: voivodeship.value, 
            city, 
            equipment: checkedCarEquipments,
            phoneNumber
        }, {abortEarly: false})
        .then((valid) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                newErrors[key] = {
                    error: false,
                    message: ""
                }
            });

            setErrors(newErrors);
        })
        .then((result) => {
            let data = {};

            data.production_year = productionYear + "-01-01";
            if(engineCapacity === ""){data.engine_capacity = null} else {data.engine_capacity = engineCapacity};
            if(enginePower === ""){data.engine_power = null} else {data.engine_power = enginePower};
            data.engine_power_unit = enginePowerUnit;
            if(price === ""){data.price = null} else {data.price = price};
            if(mileage === ""){data.mileage = null} else {data.mileage = mileage};
            if(description === ""){data.description = null} else {data.description = description};
            if(vin === ""){data.vin = null} else {data.vin = vin};
            data.voivodeship_id = voivodeship.value;
            data.fuel_type_id = fuelType.value;
            data.car_brand_id = brand.value;
            data.car_model_id = model.value;
            if(generation === null){data.car_generation_id = null} else {data.car_generation_id = generation.value};
            data.countries_of_origin_id = countryOfOrigin.value;
            data.car_body_type_id = bodyType.value;
            data.car_gearbox_type_id = gearbox.value;
            data.color_id = color.value;
            data.condition_id = condition.value;
            data.car_doors_options_id = door.value;
            if(city === ""){data.city = null} else {data.city = city};
            if(phoneNumber === ""){data.phone_number = null} else {data.phone_number = phoneNumber};

            return supabase.from("car_announcements").update(data).eq("id", id).eq("user_id", props.authentication.user.user.id);
        })
        .then(({error}) => {
            if(error) throw error;

            return supabase.from("car_announcements_has_car_equipments").delete().eq("car_announcement_id", id);
        })
        .then(({error}) => {
            if(error) throw error;
            let car_equipments = [];

            // Car equipments
            for(let i = 0; i < checkedCarEquipments.length; i++){
                if(checkedCarEquipments[i]){
                    car_equipments.push({
                        car_announcement_id: id,
                        car_equipment_id: carEquipments[i].id
                    });
                }
            }

            return supabase.from("car_announcements_has_car_equipments").insert(car_equipments);
        })
        .then(({error}) => {
            let photosRequests = [];
            if(error) throw error;

            // check what files upload
            let new_photos = howImagesAdd();

            // UPLOAD FILES
            for (let i = 0; i < new_photos.length; i++){
                let file_name = uuidv4();

                if(new_photos[i].type === "image/jpeg"){
                    file_name = file_name + ".jpeg";
                } else if(new_photos[i].type === "image/gif"){
                    file_name = file_name + ".gif";
                } else if(new_photos[i].type === "image/png"){
                    file_name = file_name + ".png";
                } else if(new_photos[i].type === "image/bmp"){
                    file_name = file_name + ".bmp";
                }
                
                let request = supabase.storage.from("car_announcements").upload(file_name, new_photos[i]);
                photosRequests.push(request);
            }

            return Promise.all(photosRequests);
        })
        .then((result) => {
            let photos_requests = [];

            // insert photos to files table
            for(let i = 0; i < result.length; i++){
                if(result[i].error) throw result[i].error;

                let dot_position = result[i].data.path.indexOf('.');
                let photo_request = supabase.from("files").insert({
                    file_name: result[i].data.path,
                    url: "https://gkphnpkzcwmhhiasezcr.supabase.co/storage/v1/object/public/" + result[i].data.fullPath,
                    file_extension: result[i].data.path.substring(dot_position + 1),
                    car_announcement_id: id
                });
            
                photos_requests.push(photo_request);
            }

            return Promise.all(photos_requests);
        })
        .then((result) => {
            let promises_array = [];
            let images_delete = getDeletedPhotos();

            // delete from files table
            images_delete.map((item) => {
                promises_array.push(supabase.from("files").delete().eq("id", item.id));
            })

            return Promise.all(promises_array);
        })
        .then((result) => {
            let images_delete = getDeletedPhotos();

            // delete from storage
            let urls = images_delete.map((item) => item.url.replace("https://gkphnpkzcwmhhiasezcr.supabase.co/storage/v1/object/public/", ""));
            
            if(urls.length > 0){
                return supabase.storage.from("car_announcements").remove(urls);
            } else {
                return;
            }
        })
        .then((result) => {
            setLoading(false);
            setSuccess(true);
        })
        .catch((error) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                let isError = false; 

                for(let i = 0; i < error.inner.length; i++){
                    if(error.inner[i].path === key){
                        newErrors[error.inner[i].path] = {
                            error: true,
                            message: error.inner[i].message
                        }

                        isError = true;
                    }
                }

                if(!isError){
                    newErrors[key] = {
                        error: false,
                        message: ""
                    }
                }
            });

            setErrors(newErrors);
            setLoading(false);
        });
    }
    
    useEffect(() => {
        let announcement_data;
        let photos;

        // securityyyy?????
        // Check if edit
        supabase.from("car_announcements").select(`
            id,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit,
            price,
            mileage,
            description,
            vin, 
            phone_number, 
            fuel_types (id, name),
            car_brands (id, name),
            car_models (id, name),
            car_generations (id, name),
            countries_of_origin (id, name),
            car_body_types (id, name),
            car_gearbox_types (id, name),
            colors (id, name),
            conditions (id, name),
            car_doors_options (id, name), 
            car_equipments (id, name), 
            voivodeships (id, name),
            city,
            files (id, url, file_name)
        `)
        .eq("id", id)
        .eq("user_id", props.authentication.user.user.id)
        .then(({data, error}) => {
            if(error) throw error;
            let production_year;
            announcement_data = data[0];

            if(announcement_data.production_year !== null){
                production_year = new Date(announcement_data.production_year);
                production_year = production_year.getFullYear();
            } else {
                production_year = "";
            }

            setBrand({value: announcement_data.car_brands.id, label: announcement_data.car_brands.name});
            setBodyType({value: announcement_data.car_body_types.id, label: announcement_data.car_body_types.name});
            setProductionYear(production_year);
            if(announcement_data.engine_capacity){setEngineCapacity(announcement_data.engine_capacity)} else {setEngineCapacity("")};
            if(announcement_data.engine_power){setEnginePower(announcement_data.engine_power)} else {setEnginePower("")};
            setEnginePowerUnit(announcement_data.engine_power_unit);
            setFuelType({value: announcement_data.fuel_types.id, label: announcement_data.fuel_types.name});
            setCountryOfOrigin({value: announcement_data.countries_of_origin.id, label: announcement_data.countries_of_origin.name});
            setCondition({value: announcement_data.conditions.id, label: announcement_data.conditions.name});
            if(announcement_data.mileage){setMileage(announcement_data.mileage)} else {setMileage("")};
            setGearbox({value: announcement_data.car_gearbox_types.id, label: announcement_data.car_gearbox_types.name});
            setColor({value: announcement_data.colors.id, label: announcement_data.colors.name});
            setDoor({value: announcement_data.car_doors_options.id, label: announcement_data.car_doors_options.name});
            if(announcement_data.vin){setVin(announcement_data.vin)} else {setVin("")};
            if(announcement_data.price !== null || announcement_data.price !== 0){setPrice(announcement_data.price)} else {setPrice("")};
            if(announcement_data.description){setDescription(announcement_data.description)} else {setDescription("")};
            setVoivodeship({value: announcement_data.voivodeships.id, label: announcement_data.voivodeships.name});
            if(announcement_data.city){setCity(announcement_data.city)} else {setCity("")};
            setCheckedCarEquipments(announcement_data.car_equipments);
            setPhotos(announcement_data.files);
            photos = announcement_data.files;
            setPhoneNumber(announcement_data.phone_number.toString());

            return getCarModels(announcement_data.car_brands.id);
        })
        .then((result) => {
            setModel({value: announcement_data.car_models.id, label: announcement_data.car_models.name});
            return getCarGenerations(announcement_data.car_models.id);
        })
        .then((result) => {
            if(announcement_data.car_generations === null){
                setGeneration(null);
            } else {
                setGeneration({value: announcement_data.car_generations.id, label: announcement_data.car_generations.name});
            }

            // download photos
            let photos_promises = [];
            for(let i = 0; i < photos.length; i++){
                photos_promises.push(fetch(photos[i].url));
            }

            return Promise.all(photos_promises)
        })
        .then((result) => {
            let photos_promises = [];
            
            // blob
            for(let i = 0; i < result.length; i++){
                photos_promises.push(result[i].blob());
            }

            return Promise.all(photos_promises);
        })
        .then((result) => {
            let files = [];

            for(let i = 0; i < result.length; i++){
                let file = new File([result[i]], photos[i].file_name, {
                    type: result[i].type
                })

                files.push(file);
            }

            return files;
        })
        .then((result) => {
            for(let i = 0; i < result.length; i++){
                result[i] = Object.assign(result[i], {preview: URL.createObjectURL(result[i])})
            }
            setPhotosFiles(result);
            setPhotosFilesServer(result);
        })
        .catch((error) => {
        });

        getCarBrands();
        getBodyTypes();
        getCarFuelTypes();
        getCountriesOfOrigin();
        getConditions();
        getGearboxTypes();
        getColors();
        getDoorsOptions();
        getVoivodeshipsOptions();
        
        let car_equipments_amount;
        let car_equipments;
        let checked_car_equipments;
        getCarEquipmentsOptions()
        .then(({data, error}) => {
            if(error) throw error;
            car_equipments = data;
            setCarEquipments(data);
            return getCarEquipmentsAmount();
        })
        .then(({count, error}) => {
            if(error) throw error;
            // set amount of car equipments
            car_equipments_amount = count;
            setCarEquipmentsAmount(count);
        })
        .then((result) => {
            // set array
            checked_car_equipments = new Array(car_equipments_amount).fill(false);
            // setCheckedCarEquipments(checked_car_equipments);

            return supabase.from("car_announcements").select(`
                car_equipments (id, name)
            `)
            .eq("id", id)
            .eq("user_id", props.authentication.user.user.id)
        })
        .then(({data, error}) => {
            if(error) throw error;
            let car_equipments_to_check = data[0].car_equipments;

            // set options
            for(let i = 0; i < car_equipments.length; i++){
                for(let j = 0; j < car_equipments_to_check.length; j++){
                    if(car_equipments[i].id === car_equipments_to_check[j].id){
                        checked_car_equipments[i] = true;
                    }
                }
            }

            setCheckedCarEquipments(checked_car_equipments);
        })
        .catch((error) => {
        });
    }, []);

    return (
        <section className="mb-64">
            <Container>
                <Row>
                    <Col xs={12} className="mb-40">
                        <h1>Edytuj ogłoszenie</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>

                        {success ? 
                        <Alert key={"success"} variant={"success"}>Ustawienia zapisane</Alert>
                        :
                        null
                        }

                        <Form onSubmit={submitForm} noValidate>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Ogólne informacje</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formBrand">
                                            <Form.Label>Marka pojazdu *</Form.Label>
                                            <Select 
                                                options={carBrands}
                                                onChange={(value) => {
                                                    setBrand(value);
                                                    setModel(null); // reset model select value
                                                    getCarModels(value.value);
                                                    setGeneration(null);
                                                    setCarGenerations([]);
                                                }} 
                                                value={brand}
                                                placeholder="Wybierz" 
                                                ref={carBrandSelectRef} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false}
                                            />
                                            {errors.brand.error && (<p className="fs-14 text-danger">{errors.brand.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formModel">
                                            <Form.Label>Model pojazdu *</Form.Label>
                                            <Select 
                                                options={carModels} 
                                                onChange={(value) => {
                                                    setModel(value); // set model value
                                                    setGeneration(null); // reset generation select value
                                                    getCarGenerations(value.value);
                                                }} 
                                                placeholder="Wybierz"
                                                value={model}
                                                isDisabled={carModels.length === 0 ? true : false} 
                                                ref={carModelSelectRef} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false}
                                            />
                                            {errors.model.error && (<p className="fs-14 text-danger">{errors.model.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formGeneration">
                                            <Form.Label>Generacja *</Form.Label>
                                            <Select 
                                                options={carGenerations}
                                                onChange={(value) => {
                                                    setGeneration(value);
                                                }} 
                                                value={generation} 
                                                placeholder="Wybierz" 
                                                isDisabled={carGenerations.length === 0 ? true : false} 
                                                ref={carGenerationSelectRef} 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }} 
                                                isSearchable={false}
                                            />
                                            {errors.generation.error && (<p className="fs-14 text-danger">{errors.generation.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formBodyType">
                                            <Form.Label>Typ nadwozia *</Form.Label>
                                            <Select 
                                                options={bodyTypes}
                                                onChange={(value) => {
                                                    setBodyType(value);
                                                }} 
                                                value={bodyType}
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.bodyType.error && (<p className="fs-14 text-danger">{errors.bodyType.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Zdjęcia</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formPhotos">
                                            <Form.Label>Zdjęcia *</Form.Label>
                                            <DropzoneImages 
                                                images={photosFiles} 
                                                onChange={setPhotosFiles} 
                                            />
                                            {errors.photos.error && (<p className="fs-14 text-danger">{errors.photos.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Szczegółowe informacje</h2>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formProductionYear">
                                            <Form.Label>Rok produkcji *</Form.Label>
                                            <Form.Control type="number" placeholder="Rok produkcji" step={1} isInvalid={errors.productionYear.error} value={productionYear} onChange={(e) => {setProductionYear(e.target.value)}} />
                                            {errors.productionYear.error && (<Form.Control.Feedback type="invalid">{errors.productionYear.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formEngineCapacity">
                                            <Form.Label>Pojemność silnika *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Pojemność silnika" isInvalid={errors.engineCapacity.error} value={engineCapacity} onChange={(e) => {setEngineCapacity(e.target.value)}} />
                                                <InputGroup.Text id="formEngineCapacityUnit">cm3</InputGroup.Text>
                                                {errors.engineCapacity.error && (<Form.Control.Feedback type="invalid">{errors.engineCapacity.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formEnginePower">
                                            <Form.Label>Moc silnika *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" aria-label="" placeholder="Moc silnika" isInvalid={errors.enginePower.error} value={enginePower} onChange={(e) => {setEnginePower(e.target.value)}} />
                                                <Form.Select aria-label="" className="flex-grow-initial flex-basis-90" value={enginePowerUnit} onChange={(e) => {setEnginePowerUnit(e.target.value)}}>
                                                    <option value="km">KM</option>
                                                    <option value="kw">KW</option>
                                                </Form.Select>
                                                {errors.enginePower.error && (<Form.Control.Feedback type="invalid">{errors.enginePower.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formLocalization">
                                            <Form.Label>Rodzaj paliwa *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="fuelType"
                                                options={fuelTypes}
                                                onChange={(value) => {
                                                    setFuelType(value);
                                                }} 
                                                value={fuelType}
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.fuelType.error && (<p className="fs-14 text-danger">{errors.fuelType.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formCountryOrigin">
                                            <Form.Label>Kraj pochodzenia *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="countryOrigin"
                                                options={countriesOfOrigin} 
                                                value={countryOfOrigin}
                                                onChange={(value) => {
                                                    setCountryOfOrigin(value);
                                                }} 
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.countryOfOrigin.error && (<p className="fs-14 text-danger">{errors.countryOfOrigin.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formCondition">
                                            <Form.Label>Stan pojazdu *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="condition"
                                                options={conditions} 
                                                value={condition}
                                                onChange={(value) => {
                                                    setCondition(value);
                                                }} 
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.condition.error && (<p className="fs-14 text-danger">{errors.condition.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formMileage">
                                            <Form.Label>Przebieg pojazdu *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Przebieg pojazdu" isInvalid={errors.mileage.error} value={mileage} onChange={(e) => {setMileage(e.target.value)}} />
                                                <InputGroup.Text id="formMileageUnit">km</InputGroup.Text>
                                                {errors.mileage.error && (<Form.Control.Feedback type="invalid">{errors.mileage.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formGearbox">
                                            <Form.Label>Skrzynia biegów *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="gearbox"
                                                options={gearboxes}
                                                onChange={(value) => {
                                                    setGearbox(value);
                                                }} 
                                                value={gearbox}
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.gearbox.error && (<p className="fs-14 text-danger">{errors.gearbox.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formColor">
                                            <Form.Label>Kolor samochodu *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="color"
                                                options={colors} 
                                                value={color}
                                                onChange={(value) => {
                                                    setColor(value);
                                                }} 
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.color.error && (<p className="fs-14 text-danger">{errors.color.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formDoors">
                                            <Form.Label>Liczba drzwi *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="doors"
                                                options={doors} 
                                                value={door}
                                                onChange={(value) => {
                                                    setDoor(value);
                                                }} 
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.door.error && (<p className="fs-14 text-danger">{errors.door.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formVIN">
                                            <Form.Label>Numer VIN</Form.Label>
                                            <Form.Control type="text" placeholder="Numer VIN" isInvalid={errors.vin.error} value={vin} onChange={(e) => {setVin(e.target.value)}} />
                                            {errors.vin.errors && (<Form.Control.Feedback type="invalid">{errors.vin.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Cena</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formPrice">
                                            <Form.Label>Cena *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Cena" isInvalid={errors.price.error} value={price} onChange={(e) => {setPrice(e.target.value)}} />
                                                <InputGroup.Text id="formPriceCurrency">zł</InputGroup.Text>
                                                {errors.price.error && (<Form.Control.Feedback type="invalid">{errors.price.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Opis</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formDescription">
                                            <Form.Label>Opis *</Form.Label>
                                            <TextEditor description={description} changeDescriptionHandler={setDescription} descriptionLength={descriptionLength} handleDescriptionLength={setDescriptionLength} />
                                            {errors.description.error && (<p className="fs-14 text-danger">{errors.description.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Lokalizacja</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formVoivodeship">
                                            <Form.Label>Województwo *</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                name="voivodeship"
                                                options={voivodeships} 
                                                value={voivodeship}
                                                onChange={(value) => {
                                                    setVoivodeship(value);
                                                }} 
                                                placeholder="Wybierz" 
                                                classNames={{
                                                    menu: (state) => "z-index-50"
                                                }}
                                            />
                                            {errors.voivodeship.error && (<p className="fs-14 text-danger">{errors.voivodeship.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formCity">
                                            <Form.Label>Miasto *</Form.Label>
                                            <Form.Control type="text" placeholder="Miasto" ref={cityInputRef} isInvalid={errors.city.error} value={city} onChange={(e) => {setCity(e.target.value)}} />
                                            {errors.city.error && (<Form.Control.Feedback type="invalid">{errors.city.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Wyposażenie</h2>
                                    </Col>
                                    {carEquipments.map((item, index) => (
                                        <Col xs={6} md={4} key={carEquipments[index].id}>
                                            <Form.Check type="checkbox" id={"carEquipment" + index.toString()} label={item.name} className="mb-8" value={item.id} checked={checkedCarEquipments[index]} onChange={() => {handleChangeCarEquipment(index)}} />
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Dane kontaktowe</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16">
                                            <Form.Label>Numer telefonu *</Form.Label>
                                            <Form.Control id="formPhoneNumber" type="number" placeholder="Numer telefonu" isInvalid={errors.phoneNumber.error} value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value)}} />
                                            <Form.Text id="formPhoneNumber" muted>Podaj numer telefonu bez prefiksu +48.</Form.Text>
                                            {errors.phoneNumber.error && (<Form.Control.Feedback type="invalid">{errors.phoneNumber.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-24">
                                <Button variant="primary" type="submit" size="lg" disabled={loading}>
                                    {loading ? <Spinner></Spinner> : "Edytuj ogłoszenie"}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default connect(
    (state) => ({authentication: state.authentication})
)(EditAdvertisement);