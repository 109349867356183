import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

function Contact(props){
    useEffect(() => {

    }, []);

    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} className="mb-40 mt-16">
                        <h1>Skontaktuj się</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <p className="mb-24">Jest to strona informacyjna. Znajdziesz tu informacje kontaktowe. Chętnie odpowiem na Twoje pytania.</p>
                        <p className="">Twórca aplikacji: <strong>Damian Komoński</strong></p>
                        <a href="mailto:info@motoso.pl" className="text-decoration-none text-grey-900 fs-20 fw-bold d-block mb-8">info@motoso.pl</a>
                        <a href="tel:+48518724187" className="text-decoration-none text-grey-900 fs-20 fw-bold d-block mb-8">tel. +48 518 724 187</a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;