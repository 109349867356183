import React, {useState, useRef, useEffect} from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactPaginate from 'react-paginate';

function MyAccountExportData(){
    function handleDownload(e){
        e.preventDefault();
    };

    function handleDownloadData(e){
        e.preventDefault();
    }

    useEffect(() => {

    }, []);

    return (
        <>
        <section className="mb-48">
            <Container>
                <Row>
                    <Col xs={12} className="mb-24 mb-md-40 mt-16">
                        <h1>Ustawienia</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={3} className="mb-40">
                        <nav>
                            <ul className="list-no-style">
                                <li className="mb-12"><Link to="/konto" className="text-decoration-none text-body">Mój profil</Link></li>
                                <li className="mb-12"><Link to="/konto/zmiana-hasla" className="text-decoration-none text-body">Zmiana hasła</Link></li>
                                <li className="mb-12"><Link to="/konto/platnosci" className="text-decoration-none text-body">Płatności</Link></li>
                                <li className="mb-12"><Link to="/konto/eksport-danych" className="text-decoration-none fw-bold">Eksport danych</Link></li>
                            </ul>
                        </nav>
                    </Col>
                    <Col xs={12} lg={{span: 8, offset: 1}}>
                        <h2 className="h3">Eksport danych</h2>
                        <p className="fs-14 mb-24 text-grey-400">Możesz pobrać tu wszystkie swoje dane które przechowujemy.</p>

                        <div className="mb-48">
                            <p>Aby pobrać wszystkie swoje dane które przechowujemy kliknij poniżej:</p>
                            <a href="#" className="btn btn-primary" onClick={handleDownloadData}>Wyeksportuj dane</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default MyAccountExportData;