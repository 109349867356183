import React, {useState, useEffect} from "react";
import { Card, Button, Container, Row, Col, Spinner, Breadcrumb } from "react-bootstrap";
import { supabase } from "../supabase_client";
import {connect} from "react-redux";
import { useParams, Link, useNavigate } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import parse from 'html-react-parser';
import profile_img from "./../assets/profile.png";
import { split_number } from "../utils/helpers";
import { v4 as uuidv4 } from 'uuid';
// import Gallery from "./../components/Gallery/Gallery";

function CarDetails(props){
    const [advertisement, setAdvertisement] = useState([]);
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("Zobacz numer");
    const [statePhoneNumber, setStatePhoneNumber] = useState(0);
    const [user, setUser] = useState("");
    let { id } = useParams();
    const navigate = useNavigate();

    function generateMonthName(month_name){
        switch(month_name){
            case 0:
                return "Styczeń";
                break;
            case 1:
                return "Luty";
                break;
            case 2:
                return "Marzec";
                break;
            case 3:
                return "Kwiecień";
                break;
            case 4:
                return "Maj";
                break;
            case 5:
                return "Czerwiec";
                break;
            case 6:
                return "Lipiec";
                break;
            case 7:
                return "Sierpień";
                break;
            case 8: 
                return "Wrzesień";
                break;
            case 9:
                return "Październik";
                break;
            case 10:
                return "Listopad";
                break;
            case 11: 
                return "Grudzień";
                break;
            default:
                return "";
        }
    }

    function get_announcement(){
        return supabase.from("car_announcements").select(`
            id,
            car_brands (name),
            car_models (name),
            car_generations (name), 
            description, 
            created_at,
            production_year,
            engine_capacity,
            engine_power,
            engine_power_unit, 
            city, 
            price, 
            mileage, 
            vin, 
            phone_number, 
            car_equipments (name), 
            voivodeships (name),
            fuel_types (name), 
            countries_of_origin (name), 
            car_body_types (name), 
            car_gearbox_types (name), 
            colors (name), 
            conditions (name), 
            car_doors_options (name), 
            files (url), 
            users (account_type, name, email, company_name)
        `)
        .eq("id", id)
        .eq("status", "COMPLETED")
        .then(({data, error}) => {
            if(error) throw error;
            let images = [];

            // If there arent announcements
            if(data.length === 0) throw new Error();
            
            // change date to year
            let new_year = new Date(data[0].production_year);
            data[0].production_year = new_year.getFullYear();
            
            for(let i = 0; i < data[0].files.length; i++){
                images.push({
                    original: data[0].files[i].url,
                    thumbnail: data[0].files[i].url
                });
            }

            // PHONE NUMBER
            data[0].phone_number = split_number(data[0].phone_number);

            // USERNAME
            if(data[0].users.account_type === "PRIVATE_PERSON"){
                if(data[0].users.name === null){
                    setUser("Użytkownik Motoso");
                } else {
                    setUser(data[0].users.name);
                }
            } else if(data[0].users.account_type === "COMPANY") {
                if(data[0].users.company_name === null){
                    setUser("Użytkownik Motoso");
                } else {
                    setUser(data[0].users.company_name);
                }
            }

            // DATE
            let date = new Date(data[0].created_at);
            let date_month = date.getMonth();
            date_month = generateMonthName(date_month);
            let date_minutes = date.getMinutes();

            if(date_minutes < 10){
                date_minutes = "0" + date_minutes;
            } else {
                date_minutes = date_minutes.toString();
            }
            data[0].created_at = date.getDate() + " " + date_month + " " + date.getFullYear() + ", " + date.getHours() + ":" + date_minutes;

            setAdvertisement(data[0]);
            setImages(images);
        })
    }

    function handlePhoneNumber(e){
        e.preventDefault(); 

        if(statePhoneNumber){
            let telLink = document.createElement('a');
            telLink.href = 'tel:' + phoneNumber;
            document.body.appendChild(telLink);
            telLink.click();
            document.body.removeChild(telLink);
        } else {
            setPhoneNumber("+48 " + advertisement.phone_number);
            setStatePhoneNumber(1);
        }
    }

    useEffect(() => {
        setLoading(true);

        get_announcement()
        .then((result) => {
            setLoading(false);
        })
        .catch((error) => {
            navigate("/");
        });
    }, [])

    return (
        <>
        {loading ? 
        <Col xs={12} className="d-flex justify-content-center py-24">
            <Spinner animation="grow" variant="secondary" />
        </Col>
        :
        <>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} className="my-16">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Strona główna</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/samochody">Samochody osobowe</Link>
                                    </li>
                                    <li className="breadcrumb-item active">{advertisement.car_brands.name + " " + advertisement.car_models.name + ", " + advertisement.production_year + "r."}</li>
                                </ol>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <section className="mb-32">
                                <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />
                            </section>
                            <section className="d-md-none border-bottom border-2 border-grey-100 mb-24">
                                <h1 className="h2">{advertisement.car_brands.name + " " + advertisement.car_models.name}</h1>
                                <h3 className="h5">{advertisement.engine_capacity + "cm3, " + advertisement.fuel_types.name + ", " + advertisement.engine_power + advertisement.engine_power_unit.toUpperCase()}</h3>
                                <p className="mb-8"><i className="bi bi-geo-alt fs-20 me-4 position-relative top-1"></i> {advertisement.city + ", " + advertisement.voivodeships.name}</p>
                                <p className="fs-24 fw-bold text-dark mb-16">{split_number(advertisement.price)} <span className="fs-20">zł</span></p>
                            </section>
                            <section className="d-md-flex mb-40">
                                <div className="w-100 w-md-50 pe-md-16">
                                    <p className="mb-4">Marka: {advertisement.car_brands.name}</p>
                                    <p className="mb-4">Model: {advertisement.car_models.name}</p>
                                    <p className="mb-4">Generacja: {advertisement.car_generations !== null ? advertisement.car_generations.name : "-"}</p>
                                    <p className="mb-4">Rodzaj paliwa: {advertisement.fuel_types.name}</p>
                                    <p className="mb-4">Rok produkcji: {advertisement.production_year}</p>
                                    <p className="mb-4">Pojemność silnika: {advertisement.engine_capacity}cm3</p>
                                    <p className="mb-4">Moc silnika: {advertisement.engine_power + advertisement.engine_power_unit.toUpperCase()}</p>
                                    <p className="mb-4">Przebieg: {split_number(advertisement.mileage)}km</p>
                                </div>
                                <div className="w-100 w-md-50 ps-md-16">
                                    <p className="mb-4">Kraj pochodzenia: {advertisement.countries_of_origin.name}</p>
                                    <p className="mb-4">Typ nadwozia: {advertisement.car_body_types.name}</p>
                                    <p className="mb-4">Skrzynia biegów: {advertisement.car_gearbox_types.name}</p>
                                    <p className="mb-4">Kolor: {advertisement.colors.name}</p>
                                    <p className="mb-4">Stan pojazdu: {advertisement.conditions.name}</p>
                                    <p className="mb-4">Liczba drzwi: {advertisement.car_doors_options.name}</p>
                                    <p className="mb-4">Numer VIN: {advertisement.vin ? advertisement.vin : "-"}</p>
                                </div>
                            </section>
                            <section className="mb-40">
                                <h2 className="h4">Wyposażenie</h2>
                                {advertisement.car_equipments.length > 0 ?
                                <ul className="list-normal">
                                    {advertisement.car_equipments.map(item => <li key={uuidv4()}>{item.name}</li>)}
                                </ul>
                                :
                                <p>Brak wyposażenia.</p>
                                }
                            </section>
                            
                            <section className="mb-24 pb-24 announcement-description">
                                <h2 className="h4">Opis</h2>
                                {parse(advertisement.description)}

                                <div className="mt-56">
                                    <p className="mb-0 text-grey-300">Data dodania: {advertisement.created_at}</p>
                                </div>
                            </section>
                        </Col>
                        <Col xs={12} md={4} className="pt-md-24">
                            <h1 className="h2 d-none d-md-block">{advertisement.car_brands.name + " " + advertisement.car_models.name}</h1>
                            <h3 className="h5 d-none d-md-block">{advertisement.engine_capacity + "cm3, " + advertisement.fuel_types.name + ", " + advertisement.engine_power + advertisement.engine_power_unit.toUpperCase()}</h3>
                            <p className="mb-24 d-none d-md-block"><i className="bi bi-geo-alt fs-20 me-4 position-relative top-1"></i> {advertisement.city + ", " + advertisement.voivodeships.name}</p>
                            <p className="fs-24 fw-bold text-dark mb-24 d-none d-md-block">{split_number(advertisement.price)} <span className="fs-20">zł</span></p>
                            <div className="border border-1 border-grey-300 rounded-4 p-12 d-flex mb-24">
                                <img src={profile_img} className="w-32 h-32 me-16 mt-4" />
                                <div>
                                    <p className="fs-12 mb-0 text-grey-400">Sprzedający:</p>
                                    <p className="mb-0">{user}</p>
                                </div>
                            </div>
                            <div className="text-center">
                                <a href="#" className="btn btn-primary btn-lg w-100 mb-16" onClick={handlePhoneNumber}>{phoneNumber}</a>
                                <a href={"mailto:" + advertisement.users.email} className="link-normal">Wyślij wiadomość email</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="announcement_number">
                <a href="#" className="btn btn-primary w-100" onClick={handlePhoneNumber}>{phoneNumber}</a>
            </div>
        </>
        }
    </>
    );
}

export default connect(
    (state) => ({authentication: state.authentication})
)(CarDetails);