import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import authentication from "./reducers/authentication";

const reducer = combineReducers({
    authentication
});

export default createStore(
    reducer, 
    composeWithDevTools(applyMiddleware(thunk))
);