import React, {useState, useRef, useEffect} from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactPaginate from 'react-paginate';

function MyAccountPayments(){
    const [password, setPassword] = useState(null);
    const passwordRef = useRef();
    const [itemOffset, setItemOffset] = useState(5);
    const [paymentsLength, setPaymentsLength] = useState(200);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const schema = yup.object({
        password: yup.string("Podaj prawidłowe hasło").required("Podaj proszę prawidłowe aktualne hasło"),
        new_password: yup.string("Podaj prawidłowe hasło").required("Podaj proszę prawidłowe nowe hasło"),
        repeat_new_password: yup.string("Podaj prawidłowe hasło").required("Podaj proszę prawidłowe nowe hasło")
    });

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    function handleDownload(e){
        e.preventDefault();
    };

    function handlePageClick(event){
        const newOffset = (event.selected * itemsPerPage) % paymentsLength;
        setItemOffset(newOffset);
    };

    function submitForm(data){
    }

    useEffect(() => {
        setPageCount(Math.ceil(paymentsLength / itemsPerPage));
    }, [itemOffset]);

    return (
        <>
        <section className="mb-48">
            <Container>
                <Row>
                    <Col xs={12} className="mb-24 mb-md-40 mt-16">
                        <h1>Ustawienia</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={3} className="mb-40">
                        <nav>
                            <ul className="list-no-style">
                                <li className="mb-12"><Link to="/konto" className="text-decoration-none text-body">Mój profil</Link></li>
                                <li className="mb-12"><Link to="/konto/zmiana-hasla" className="text-decoration-none text-body">Zmiana hasła</Link></li>
                                <li className="mb-12"><Link to="/konto/platnosci" className="text-decoration-none fw-bold">Płatności</Link></li>
                                <li className="mb-12"><Link to="/konto/eksport-danych" className="text-decoration-none text-body">Eksport danych</Link></li>
                            </ul>
                        </nav>
                    </Col>
                    <Col xs={12} lg={{span: 8, offset: 1}}>
                        <h2 className="h3">Płatności</h2>
                        <p className="fs-14 mb-24 text-grey-400">Znajdziesz tu wszystkie płatności dokonane przez Ciebie i zatwierdzone. Możesz pobrać fakturę.</p>

                        <div className="p-16 shadow-sm rounded-8 d-md-flex align-items-center justify-content-between mb-8">
                            <div className="mb-16 mb-md-0">
                                <h3 className="h5">Volkswagen Polo 9N3, 2020r.</h3>
                                <p className="fs-14 text-grey-400 mb-4">Pojemność silnika: 1500cm3</p>
                                <p className="fs-14 text-grey-400 mb-4">Moc silnika: 150KM</p>
                            </div>
                            <div className="mb-16 mb-md-0">
                                <p className="mb-0 fs-12 text-grey-400">Data płatności:</p>
                                <p className="mb-0">12.01.2024r.</p>
                            </div>
                            <div className="">
                                <a href="#" onClick={handleDownload} className="link-normal-icon me-8">
                                    <i class="bi bi-file-earmark-arrow-down"></i> Pobierz
                                </a>
                            </div>
                        </div>

                        <div className="p-16 shadow-sm rounded-8 d-md-flex align-items-center justify-content-between mb-8">
                            <div className="mb-16 mb-md-0">
                                <h3 className="h5">Volkswagen Polo 9N3, 2020r.</h3>
                                <p className="fs-14 text-grey-400 mb-4">Pojemność silnika: 1500cm3</p>
                                <p className="fs-14 text-grey-400 mb-4">Moc silnika: 150KM</p>
                            </div>
                            <div className="mb-16 mb-md-0">
                                <p className="mb-0 fs-12 text-grey-400">Data płatności:</p>
                                <p className="mb-0">12.01.2024r.</p>
                            </div>
                            <div className="">
                                <a href="#" onClick={handleDownload} className="link-normal-icon me-8">
                                    <i class="bi bi-file-earmark-arrow-down"></i> Pobierz
                                </a>
                            </div>
                        </div>


                        <div className="mt-40 d-flex justify-content-center">
                            <ReactPaginate
                                nextLabel="Następne"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                previousLabel="Poprzednie"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="link-previous"
                                previousLinkClassName="link-previous-link"
                                nextClassName="link-next"
                                nextLinkClassName="link-next-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default MyAccountPayments;