import React from "react";
import { connect } from "react-redux";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { signOutThunk } from "../../reducers/authentication";

function Header(props){
    const navigate = useNavigate();

    function signOutHandle(e){
        e.preventDefault();
        props.signOutThunk();
    }

    return (
        <header className="mb-12">
            <Navbar expand="md">
                <Container>
                    <Link to="/" className="navbar-brand">Motozo</Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/samochody" role="button" className="nav-link">Samochody osobowe</Link>
                            {/* <Link to="/motocykle" role="button" className="nav-link">Motocykle</Link> */}
                        </Nav>
                        
                        {!props.authentication.user ? 
                            <Nav>
                                <Link to="/logowanie" className="nav-link me-2" role="button">Zaloguj się</Link>
                                <Link to="/rejestracja" className="btn btn-primary" role="button">Zarejestruj się</Link>
                            </Nav>
                            : 
                            <>
                            <Nav className="">
                                <NavDropdown title="Twoje konto" className="me-8">
                                    <NavDropdown.Item href="#" onClick={(e) => {e.preventDefault(); navigate("/moje-ogloszenia")}}>Moje ogłoszenia</NavDropdown.Item>
                                    <NavDropdown.Item href="#" onClick={(e) => {e.preventDefault(); navigate("/konto")}}>Ustawienia</NavDropdown.Item>
                                    <NavDropdown.Item href="#" onClick={signOutHandle}>Wyloguj się</NavDropdown.Item>
                                </NavDropdown>
                                <div className="nav-item">
                                    <Link to="/nowe-ogloszenie" className="btn btn-primary" role="button">Dodaj ogłoszenie</Link>  
                                </div>
                            </Nav>
                            </>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default connect(
    (state) => ({authentication: state.authentication}), 
    { signOutThunk }
)(Header);