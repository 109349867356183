import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "./../../supabase_client.js";
import Spinner from "./../../components/Spinner/Spinner.js"
import { object, string, number, date, InferType } from 'yup';

function ForgotPasswordChange(props){
    let [password, setPassword] = useState("");
    let [repeatPassword, setRepeatPassword] = useState("");
    let [loading, setLoading] = useState(false);
    let [success, setSuccess] = useState(false);
    let [errors, setErrors] = useState({
        password: {
            error: false,
            message: ""
        },
        repeatPassword: {
            error: false,
            message: ""
        }
    });
    let [formError, setFormError] = useState("");
    const navigate = useNavigate();

    let formSchema = object({
        password: string()
        .min(6, "Hasło musi mieć minimum 6 znaków")
        .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .test(
            "the-same-password", 
            "Hasła muszą być identyczne. Proszę popraw.", 
            (value, context) => {
                if(value === repeatPassword){
                    return true;
                } else {
                    return false;
                }
            }).required("Hasło jest wymagane"),
        repeatPassword: string()
        .min(6, "Hasło musi mieć minimum 6 znaków.")
        .matches(/[A-Z]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/\d/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Hasło powinno zawierać przynajmniej jedną wielką literę, jedną cyfrę i znak specjalny.")
        .test(
            "the-same-repeatpassword", 
            "Hasła muszą być identyczne. Proszę popraw.", 
            (value, context) => {
                if(value === password){
                    return true;
                } else {
                    return false;
                }
            }).required("Hasło jest wymagane"),
      });

    function passwordHandleChange(event){
        setPassword(event.target.value);
    }

    function repeatPasswordHandleChange(event){
        setRepeatPassword(event.target.value);
    }

    function handleSubmit(event){
        event.preventDefault();

        // Form validation
        const form = formSchema.validate({
            password,
            repeatPassword
        }, {abortEarly: false})
        .then((valid) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                newErrors[key] = {
                    error: false,
                    message: ""
                }
            });

            setErrors(newErrors);
        })
        .then((result) => {
            return supabase.auth.updateUser({
                password
            })
        })
        .then(({data, error}) => {
            if(error) throw new Error(error);
            setSuccess(true);
        })
        .catch((error) => {
            let newErrors = {...errors};

            Object.keys(newErrors).forEach((key) => {
                let isError = false; 

                for(let i = 0; i < error.inner.length; i++){
                    if(error.inner[i].path === key){
                        newErrors[error.inner[i].path] = {
                            error: true,
                            message: error.inner[i].message
                        }

                        isError = true;
                    }
                }

                if(!isError){
                    newErrors[key] = {
                        error: false,
                        message: ""
                    }
                }
            });

            setErrors(newErrors);
        });
    }


    useEffect(() => {

    }, []);

    if(success){
        return <section>
        <Container>
            <Row>
                <Col xs={12} sm={{span: 6, offset: 3}}>
                    <h1 className="h2 mb-24">Zapomniałem hasła</h1>
                    <p className="mb-48">Hasło zostało zmienione. Dziękujemy</p>
                </Col>
            </Row>
        </Container>
    </section>
    } else {
        return <section>
            <Container>
                <Row>
                    <Col xs={12} sm={{span: 6, offset: 3}}>
                        <h1 className="h2 mb-24">Zapomniałem hasła</h1>
                        <p>Wypełnij formularz, aby zmienić hasło.</p>

                        {formError ? 
                        <div className="mt-4">
                            <Alert variant="danger">{formError}</Alert>
                        </div>
                        : 
                        null
                        }

                        <Form onSubmit={handleSubmit} className="mb-40">
                            <Form.Group className="mb-16" controlId="formBasicEmail">
                                <Form.Label>Hasło</Form.Label>
                                <Form.Control type="password" placeholder="Hasło" name="passwordInput" isInvalid={errors.password.error} onChange={passwordHandleChange} value={password} required />
                                <Form.Text id="passwordHelpBlock" muted>Hasło musi zawierać przynajmniej jedną cyfrę i znak specjalny. Minimalnie 6 znaków.</Form.Text>
                                {errors.password.error && (<Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>)}
                            </Form.Group>
                            <Form.Group className="mb-16" controlId="formBasicEmail">
                                <Form.Label>Powtórz hasło</Form.Label>
                                <Form.Control type="password" placeholder="Powtórz hasło" name="repeatPasswordInput" isInvalid={errors.repeatPassword.error} onChange={repeatPasswordHandleChange} value={repeatPassword} required />
                                {errors.repeatPassword.error && (<Form.Control.Feedback type="invalid">{errors.repeatPassword.message}</Form.Control.Feedback>)}
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading} className="w-100 mt-24">
                                {loading ? <Spinner></Spinner> : "Zmień hasło"}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    }
}

export default ForgotPasswordChange;