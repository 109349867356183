import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { fetchUser } from "../reducers/authentication";
import { useNavigate, Link } from "react-router-dom";

function SignIn(props){
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    const navigate = useNavigate();

    function emailHandleChange(event){
        setEmail(event.target.value);
    }

    function passwordHandleChange(event){
        setPassword(event.target.value);
    }

    function handleSubmit(event){
        event.preventDefault();
        props.fetchUser(email, password);
    }
    useEffect(() => {
        if(props.authentication.success){
            navigate("/");
        }

    }, [props.authentication.success]);

    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} sm={{span: 6, offset: 3}}>
                        <h1 className="h2 mb-24">Zaloguj się</h1>

                        {props.authentication.error ? 
                        <div className="mt-4">
                            <Alert variant="danger">{props.authentication.error}</Alert>
                        </div>
                        : 
                        null
                        }

                        <Form onSubmit={handleSubmit} className="mb-40">
                            <Form.Group className="mb-8" controlId="formBasicEmail">
                                <Form.Label>Adres email</Form.Label>
                                <Form.Control type="email" placeholder="Adres email" name="emailInput" onChange={emailHandleChange} value={email} required />
                            </Form.Group>
                            <Form.Group className="mb-8" controlId="formBasicPassword">
                                <Form.Label>Hasło</Form.Label>
                                <Form.Control type="password" placeholder="Hasło" name="passwordInput" onChange={passwordHandleChange} value={password} required />
                            </Form.Group>
                            <div className="my-16">
                                <Link to="/zapomnialem-hasla">Zapomniałem hasła</Link>
                            </div>
                            <Button variant="primary" type="submit" disabled={props.authentication.loading} className="w-100">Zaloguj się</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default connect(
    (state) => ({authentication: state.authentication}),    
    { fetchUser }
)(SignIn);