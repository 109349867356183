import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { supabase } from "./../supabase_client";
import { useNavigate, useParams } from "react-router-dom";
import {connect} from "react-redux";

import DropzoneImages from "./../components/DropzoneImages/DropzoneImages";
import TextEditor from "../components/TextEditor/TextEditor";

function NewAdvertisementCar(props){
    const [carEquipments, setCarEquipments] = useState([]);
    const [carBrands, setCarBrands] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [carGenerations, setCarGenerations] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [countriesOfOrigin, setCountriesOfOrigin] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [gearbox, setGearbox] = useState([]);
    const [colors, setColors] = useState([]);
    const [voivodeships, setVoivodeships] = useState([]);
    const [model, setModel] = useState(null);
    const [brand, setBrand] = useState(null);
    const [generation, setGeneration] = useState(null);
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [doorsOptions, setDoorsOptions] = useState([]);
    const carBrandSelectRef = useRef();
    const carModelSelectRef = useRef();
    const carGenerationSelectRef = useRef();
    const cityInputRef = useRef();
    const navigate = useNavigate();
    const { id } = useParams();

    const schema = yup.object({
        brand: yup.string().test(
            "proper-brand-id", 
            "Proszę wybierz markę pojazdu", 
            (value, context) => {
                let valid = false;
                carBrands.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz markę pojazdu."),
        model: yup.string().test(
            "proper-model-id", 
            "Proszę wybierz model pojazdu", 
            (value, context) => {
                let valid = false;
                carModels.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz model pojazdu."),
        generation: yup.string().test(
            "proper-generation-id", 
            "Proszę wybierz generacje pojazdu",
            (value, context) => {
                let valid = false;

                carGenerations.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });

                if(carGenerations.length === 0){
                    return true;
                }
                
                return valid;
            })
            .nullable()
            .optional(),
        bodyType: yup.string().test(
            "proper-bodyType", 
            "Proszę wybierz typ nadwozia", 
            (value, context) => {
                let valid = false;
                bodyTypes.map((element) => {
                    // value is ID
                    if(value === element.value){
                        valid = true;
                    }

                    return element;
                });
                return valid;
            })
            .required("Proszę wybierz typ nadwozia pojazdu."),
        photos: yup.array().min(1, "Proszę dodaj zdjęcie."),
        productionYear: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Wprowadź poprawny rok produkcji.").integer("Wprowadź poprawny rok produkcji.").min(1800, "Rok produkcji jest zbyt niski. Wprowadź poprawny.").max(new Date().getFullYear(), "Zbyt wysoki rok produkcji. Wprowadź poprawny.").required("Proszę wprowadź rok produkcji."),
        engineCapacity: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Wprowadź poprawną pojemność silnika.").integer("Wprowadź poprawną pojemność silnika.").required("Proszę wprowadź pojemność silnika."),
        enginePower: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Wprowadź poprawną moc silnika.").integer("Wprowadź poprawną moc silnika.").required("Proszę wprowadź moc silnika."),
        enginePowerUnit: yup.string("Wprowadź poprawną jednostkę mocy silnika.").required("Proszę wybierz jednostkę mocy silnika."),
        fuelType: yup.string().required("Proszę wybierz rodzaj paliwa."),
        countryOrigin: yup.string().required("Proszę wybierz kraj pochodzenia pojazdu."),
        condition: yup.string().required("Proszę wybierz stan pojazdu."),
        mileage: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Wprowadź poprawny przebieg pojazdu.").integer("Wprowadź poprawny przebieg pojazdu.").required("Proszę wprowadź przebieg pojazdu."),
        gearbox: yup.string().required("Proszę wybierz rodzaj skrzyni biegów."),
        color: yup.string().required("Proszę wybierz kolor pojazdu."),
        doors: yup.string().required("Proszę wybierz liczbe drzwi."),
        vin: yup.lazy((value) => value === "" ? yup.string() : yup.string().min(17, "Podany numer VIN jest zbyt krótki.").max(17, "Podany numer VIN jest za długi.")),
        price: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Proszę podaj poprawną cenę pojazdu.").required("Proszę podaj cenę pojazdu."),
        description: yup.string().test(
            "description_length", 
            "Opis jest zbyt krótki. Musi posiadać minimum 50 znaków.", 
            (value, context) => {
                return (descriptionLength < 100 ? false : true)
            })
            .required("Proszę wpisz opis."),
        voivodeship: yup.string().required("Proszę wybierz województwo."),
        city: yup.string().required("Proszę wpisz miasto."),
        equipment: yup.array(), 
        phoneNumber: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().test(
            "phone_number_valid", 
            "Numer jest nieprawidłowy", 
            (value, context) => {
                let phone_number = value.toString();
                return (phone_number.length === 10 || phone_number.length === 9 ? true : false)
            }).required("Proszę podaj numer telefonu")
    });

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            generation: null,
            photos: []
        }
    });

    function getCarBrands(){
        return supabase.from("car_brands").select();
    }

    function getCarModels(brand_id){
        supabase.from("car_models").select().eq("car_brand_id", brand_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarModels(new_data);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    function getCarGenerations(model_id){
        supabase.from("car_generations").select().eq("car_model_id", model_id)
        .then(({data, error}) => {
            let new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarGenerations(new_data);
        })
        .catch((error) => {
            console.error(error);
        })
    }

    function getBodyTypes(){
        return supabase.from("car_body_types").select();
    }

    function getCarFuelTypes(){
        return supabase.from("fuel_types").select();
    }

    function getCountriesOfOrigin(){
        return supabase.from("countries_of_origin").select();
    }

    function getConditions(){
        return supabase.from("conditions").select();
    }

    function getGearboxTypes(){
        return supabase.from("car_gearbox_types").select();
    }

    function getColors(){
        return supabase.from("colors").select();
    }

    function getDoorsOptions(){
        return supabase.from("car_doors_options").select();
    }

    function getVoivodeshipsOptions(){
        return supabase.from("voivodeships").select();
    }

    function getCarEquipmentsOptions(){
        return supabase.from("car_equipments").select();
    }

    function editAnnouncement(data){

    }

    function promiseOptions(inputValue){
        return new Promise((resolve, reject) => {
            if(inputValue.length < 3){
                resolve([])
            } else {
                console.log(process.env.REACT_APP_CITIES);
                fetch("https://wft-geo-db.p.rapidapi.com/v1/geo/places?namePrefix=Mie&types=CITY&limit=10&countryIds=pl&minPopulation=30000", {
                    method: "GET",
                    headers: {
                        "X-RapidAPI-Key": process.env.REACT_APP_CITIES,
                        "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com"
                    }
                })
                .then((result) => result.json())
                .then((result) => {
                    let cities = [];
                    console.log(result);
                    cities = result.data.map((item) => {
                        return {
                            value: item.name,
                            label: item.name
                        }
                    });
                    resolve(cities);
                    console.log(result);
                })
                .catch((error) => {
                    console.log(error);
                    reject("abc");
                })
            }
            
        })
    };

    function submitForm(data){
        console.log(data);
        props.handleFormDataChange(data);
        navigate("/nowe-ogloszenie?page=3&category=car");
    }

    useEffect(() => {
        // Check if edit
        if(props.type === "edit"){
            supabase.from("car_announcements").select()
            .eq("id", id)
            .eq("user_id", props.authentication.user.user.id)
            .then(({data, error}) => {
                if(error) throw new Error();
                // musze znalezc label w carBrands
                setBrand({value: data[0].car_brand_id, label: "asfasf"});
            })
            .catch((error) => {
                navigate("/");
            })
        }

        // Get car brands
        getCarBrands()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCarBrands(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        // Get car body types
        getBodyTypes()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            
            setBodyTypes(new_data);
        })
        .catch((error) => {
            console.error(error);
        });


        getCarFuelTypes()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setFuelTypes(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getCountriesOfOrigin()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setCountriesOfOrigin(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getConditions()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setConditions(new_data);
        })
        .catch((error) => {
            console.error(error)
        });

        getGearboxTypes()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setGearbox(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getColors()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });

            setColors(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getDoorsOptions()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            });

            setDoorsOptions(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getVoivodeshipsOptions()
        .then(({data, error}) => {
            const new_data = data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            });

            setVoivodeships(new_data);
        })
        .catch((error) => {
            console.error(error);
        });

        getCarEquipmentsOptions()
        .then(({data, error}) => {
            setCarEquipments(data);
        })
        .catch((error) => {
            console.error(error);
        })
    }, []);

    return (
        <section className="mb-64">
            <Container>
                <Row>
                    <Col xs={12} className="mb-40">
                        {props.type === "add" ? <h1>Dodaj ogłoszenie</h1> : null}
                        {props.type === "edit" ? <h1>Edytuj ogłoszenie</h1> : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <Form onSubmit={handleSubmit(submitForm)} noValidate>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Ogólne informacje</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formBrand">
                                            <Form.Label>Marka pojazdu *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="brand"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select 
                                                        options={carBrands}
                                                        onChange={(value) => {
                                                            onChange(value.value);
                                                            setBrand(value);
                                                            setModel(null); // reset model select value
                                                            getCarModels(value.value);
                                                            setGeneration(null);
                                                            setCarGenerations([]);
                                                        }} 
                                                        value={brand}
                                                        placeholder="Wybierz" 
                                                        ref={carBrandSelectRef} 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )}
                                            />
                                            {errors.brand && (<p className="fs-14 text-danger">{errors.brand.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formModel">
                                            <Form.Label>Model pojazdu *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="model"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select 
                                                        options={carModels}
                                                        onChange={(value) => {
                                                            onChange(value.value); // passing model ID
                                                            setModel(value); // set model value
                                                            setGeneration(null); // reset generation select value
                                                            getCarGenerations(value.value);
                                                        }} 
                                                        placeholder="Wybierz"
                                                        value={model}
                                                        isDisabled={carModels.length === 0 ? true : false} 
                                                        ref={carModelSelectRef} 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )} 
                                            />
                                            {errors.model && (<p className="fs-14 text-danger">{errors.model.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formGeneration">
                                            <Form.Label>Generacja *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="generation"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select 
                                                        options={carGenerations}
                                                        onChange={(value) => {
                                                            onChange(value.value);
                                                            setGeneration(value);
                                                        }} 
                                                        value={generation} 
                                                        placeholder="Wybierz" 
                                                        isDisabled={carGenerations.length === 0 ? true : false} 
                                                        ref={carGenerationSelectRef} 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )}
                                            />
                                            {errors.generation && (<p className="fs-14 text-danger">{errors.generation.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formBodyType">
                                            <Form.Label>Typ nadwozia *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="bodyType"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select 
                                                        options={bodyTypes}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }}
                                                    />
                                                )} 
                                                isSearchable={true}
                                            />
                                            {errors.bodyType && (<p className="fs-14 text-danger">{errors.bodyType.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Zdjęcia</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formPhotos">
                                            <Form.Label>Zdjęcia *</Form.Label>
                                            <Controller 
                                                name="photos" 
                                                control={control} 
                                                render={({
                                                    field: {onChange, onBlur, value},
                                                    fieldState: {invalid, isTouched, isDirty, error} 
                                                }) => (
                                                    <DropzoneImages onChange={onChange} images={value} />
                                                )} />

                                            {errors.photos && (<p className="fs-14 text-danger">{errors.photos.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Szczegółowe informacje</h2>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formProductionYear">
                                            <Form.Label>Rok produkcji *</Form.Label>
                                            <Form.Control type="number" placeholder="Rok produkcji" step={1} isInvalid={Boolean(errors.productionYear)} {...register("productionYear")}/>
                                            {errors.productionYear && (<Form.Control.Feedback type="invalid">{errors.productionYear.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formEngineCapacity">
                                            <Form.Label>Pojemność silnika *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Pojemność silnika" isInvalid={Boolean(errors.engineCapacity)} {...register("engineCapacity")}/>
                                                <InputGroup.Text id="formEngineCapacityUnit">cm3</InputGroup.Text>
                                                {errors.engineCapacity && (<Form.Control.Feedback type="invalid">{errors.engineCapacity.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formEnginePower">
                                            <Form.Label>Moc silnika *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" aria-label="" placeholder="Moc silnika" isInvalid={Boolean(errors.enginePower)} {...register("enginePower")}/>
                                                <Form.Select aria-label="" className="flex-grow-initial flex-basis-90" {...register("enginePowerUnit")}>
                                                    <option value="km">KM</option>
                                                    <option value="kw">KW</option>
                                                </Form.Select>
                                                {errors.enginePower && (<Form.Control.Feedback type="invalid">{errors.enginePower.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formLocalization">
                                            <Form.Label>Rodzaj paliwa *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="fuelType"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        name="fuelType"
                                                        options={fuelTypes}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )}
                                            />
                                            {errors.fuelType && (<p className="fs-14 text-danger">{errors.fuelType.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formCountryOrigin">
                                            <Form.Label>Kraj pochodzenia *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="countryOrigin"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        name="countryOrigin"
                                                        options={countriesOfOrigin}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )}
                                            />
                                            {errors.countryOrigin && (<p className="fs-14 text-danger">{errors.countryOrigin.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formCondition">
                                            <Form.Label>Stan pojazdu *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="condition"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        name="condition"
                                                        options={conditions}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }} 
                                                        isSearchable={true}
                                                    />
                                                )}
                                            />
                                            {errors.condition && (<p className="fs-14 text-danger">{errors.condition.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formMileage">
                                            <Form.Label>Przebieg pojazdu *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Przebieg pojazdu" isInvalid={Boolean(errors.mileage)} {...register("mileage")} />
                                                <InputGroup.Text id="formMileageUnit">km</InputGroup.Text>
                                                {errors.mileage && (<Form.Control.Feedback type="invalid">{errors.mileage.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formGearbox">
                                            <Form.Label>Skrzynia biegów *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="gearbox"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        isSearchable={true}
                                                        name="gearbox"
                                                        options={gearbox}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.gearbox && (<p className="fs-14 text-danger">{errors.gearbox.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formColor">
                                            <Form.Label>Kolor samochodu *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="color"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        isSearchable={true}
                                                        name="color"
                                                        options={colors}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.color && (<p className="fs-14 text-danger">{errors.color.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formDoors">
                                            <Form.Label>Liczba drzwi *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="doors"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        isSearchable={true}
                                                        name="doors"
                                                        options={doorsOptions}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.doors && (<p className="fs-14 text-danger">{errors.doors.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-16" controlId="formVIN">
                                            <Form.Label>Numer VIN</Form.Label>
                                            <Form.Control type="text" placeholder="Numer VIN" isInvalid={Boolean(errors.vin)} {...register("vin")} />
                                            {errors.vin && (<Form.Control.Feedback type="invalid">{errors.vin.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Cena</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formPrice">
                                            <Form.Label>Cena *</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="number" placeholder="Cena" isInvalid={Boolean(errors.price)} {...register("price")} />
                                                <InputGroup.Text id="formPriceCurrency">zł</InputGroup.Text>
                                                {errors.price && (<Form.Control.Feedback type="invalid">{errors.price.message}</Form.Control.Feedback>)}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Opis</h2>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-16" controlId="formDescription">
                                            <Form.Label>Opis *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="description"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <TextEditor description={value} changeDescriptionHandler={onChange} descriptionLength={descriptionLength} handleDescriptionLength={setDescriptionLength} />
                                                )}
                                            />
                                            {errors.description && (<p className="fs-14 text-danger">{errors.description.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Lokalizacja</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formVoivodeship">
                                            <Form.Label>Województwo *</Form.Label>
                                            <Controller
                                                control={control}
                                                name="voivodeship"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Select
                                                        isSearchable={true}
                                                        name="voivodeship"
                                                        options={voivodeships}
                                                        onChange={value => onChange(value.value)} 
                                                        placeholder="Wybierz" 
                                                        classNames={{
                                                            menu: (state) => "z-index-50"
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.voivodeship && (<p className="fs-14 text-danger">{errors.voivodeship.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16" controlId="formCity">
                                            <Form.Label>Miasto *</Form.Label>
                                            {/* <Controller
                                                control={control}
                                                name="city"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} noOptionsMessage={(obj) => {console.log(obj)}} />
                                                )}
                                            /> */}
                                            <Form.Control type="text" placeholder="Miasto" ref={cityInputRef} isInvalid={Boolean(errors.city)} {...register("city")} />
                                            {errors.city && (<Form.Control.Feedback type="invalid">{errors.city.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Wyposażenie</h2>
                                    </Col>
                                    {carEquipments.length > 0 
                                    ?
                                    carEquipments.map((item, index) => (
                                        <Col xs={6} md={4} key={carEquipments[index].id + index.toString()}>
                                            <Form.Check type="checkbox" id={"formEquipment" + index.toString()} label={item.name} className="mb-8" value={item.id} {...register("equipment")} />
                                        </Col>
                                    ))
                                    : 
                                    null
                                    }
                                </Row>
                            </div>
                            <div className="mb-24">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="h4 mb-16">Dane kontaktowe</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group className="mb-16">
                                            <Form.Label>Numer telefonu *</Form.Label>
                                            <Form.Control id="formPhoneNumber" type="number" placeholder="Numer telefonu" isInvalid={Boolean(errors.phoneNumber)} {...register("phoneNumber")} />
                                            <Form.Text id="formPhoneNumber" muted>Podaj numer telefonu bez prefiksu +48.</Form.Text>
                                            {errors.phoneNumber && (<Form.Control.Feedback type="invalid">{errors.phoneNumber.message}</Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-24">
                                <Button type="submit" variant="primary" size="lg">Dodaj ogłoszenie</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default connect(
    (state) => ({authentication: state.authentication})
)(NewAdvertisementCar);