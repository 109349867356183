import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// export const stripePromise = loadStripe("pk_test_51MJQbKFsJJlT5l7JxQQW9YkEZFL5eH0P719kCpzolcL9E86xdejJwr3ee2umz0wKHR6LTzPhpjDlzs4lDzwgNZ2P005beyEASb");
export const stripePromise = loadStripe("pk_live_51MJQbKFsJJlT5l7J850KxVJ1uTgi8G85bK7yeAx2jGYXwjYNV2Mz2LOo4Aj429wXjFpI6rJRaXYzX2hBmcgSm9w7002ddKQT8L");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
