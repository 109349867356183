import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer(){
    return (
        <footer className="py-16 mb-40 mt-40">
            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Link to="/" className="d-flex align-items-center mb-16 link-dark text-decoration-none fs-24">Motozo</Link>
                        <p className="fs-14 mb-32">Ogłoszenia samochodów, motocykli, samochodów ciężarowych, pojazdów rolniczych, części używanych.</p>
                        <p className="text-muted fs-14">Wszelkie prawa zastrzeżone © 2024</p>
                    </Col>

                    {/* <div className="col mb-3">
                        <Link to="/" className="d-flex align-items-center mb-16 link-dark text-decoration-none fs-20">Motoso</Link>
                        <p className="fs-14">Ogłoszenia samochodów, motocykli, samochodów ciężarowych, pojazdów rolniczych, części używanych.</p>
                        <p className="text-muted fs-14">Wszelkie prawa zastrzeżone © 2024</p>
                    </div> */}
{/* 
                    <div className="col mb-3">

                    </div> */}
                    <Col xs={12} md={{span: 2, offset: 2}} className="footer-nav">
                        <h5>Kategorie</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-8"><Link to="/samochody" className="text-grey-500 text-decoration-none">Samochody osobowe</Link></li>
                        </ul>
                    </Col>

                    <Col xs={12} md={2} className="footer-nav">
                        <h5>Pomoc</h5>
                        <ul className="nav flex-column">
                            {/* <li className="nav-item mb-8"><Link to="" className="text-grey-500 text-decoration-none">Regulamin</Link></li> */}
                            <li className="nav-item mb-8"><Link to="/skontaktuj-sie" className="text-grey-500 text-decoration-none">Skontaktuj się</Link></li>
                        </ul>
                    </Col>

                    <Col xs={12} md={2} className="footer-nav">
                        <h5>Media społ.</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-8"><a href="https://www.facebook.com/profile.php?id=61559314365175" className="text-grey-500 text-decoration-none" target="_blank">Facebook</a></li>
                            {/* <li className="nav-item mb-8"><Link to="" className="text-grey-500 text-decoration-none">Instagram</Link></li> */}
                            {/* <li className="nav-item mb-8"><Link to="" className="text-grey-500 text-decoration-none">LinkedIn</Link></li> */}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;