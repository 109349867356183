import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "./../../supabase_client.js";

function ForgotPassword(props){
    let [email, setEmail] = useState("");
    let [loading, setLoading] = useState(false);
    let [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    function emailHandleChange(event){
        setEmail(event.target.value);
    }

    function handleSubmit(event){
        event.preventDefault();
        setLoading(true);
        supabase.auth.resetPasswordForEmail(email, {
            redirectTo: "https://motoso.pl/zapomnialem-hasla-zmiana"
        })
        .then(({data, error}) => {
            if(error) throw new Error();
            // navigate("/zapomnialem-hasla-success");
            setSuccess(true);
        })
        .catch((error) => {
        })
    }

    useEffect(() => {

    }, [props.authentication.success]);

    if(success){
        return <section>
        <Container>
            <Row>
                <Col xs={12} sm={{span: 6, offset: 3}}>
                    <div className="mb-24">
                        <Link to="/logowanie"><i className=""></i> Wróć do logowania</Link>
                    </div>
                    <h1 className="h2 mb-24">Zapomniałem hasła</h1>
                    <p className="mb-48"><strong>Wysłaliśmy link do zmiany hasła.</strong> Proszę sprawdź swój email i kliknij w link.</p>
                </Col>
            </Row>
        </Container>
    </section>
    } else {
        return <section>
        <Container>
            <Row>
                <Col xs={12} sm={{span: 6, offset: 3}}>
                    <div className="mb-24">
                        <Link to="/logowanie"><i className="bi bi-chevron-left"></i> Wróć do logowania</Link>
                    </div>
                    <h1 className="h2 mb-24">Zapomniałem hasła</h1>
                    <p>Zapomniałeś hasła? Wpisz swój adres email. Otrzymasz link który pozwoli Ci zresetować hasło.</p>

                    {0 ? 
                    <div className="mt-4">
                        <Alert variant="danger">{props.authentication.error}</Alert>
                    </div>
                    : 
                    null
                    }

                    <Form onSubmit={handleSubmit} className="mb-40">
                        <Form.Group className="mb-32" controlId="formBasicEmail">
                            <Form.Label>Adres email</Form.Label>
                            <Form.Control type="email" placeholder="Adres email" name="emailInput" onChange={emailHandleChange} value={email} required />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading} className="w-100">Zresetuj hasło</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    </section>
    }
}

export default connect(
    (state) => ({authentication: state.authentication}),    
    { }
)(ForgotPassword);