import { supabase } from "../supabase_client";

// ------------------ Initial State ------------------
const initialState = {
    user: null,
    error: "",
    success: false,
    loading: false,
    authIsReady: false
};

// ------------------ Action types (constants) ------------------
const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
const SIGN_IN_FAIL = "SIGN_IN_FAIL";
const SIGN_IN_LOADING = "SIGN_IN_LOADING";
const SIGN_OUT = "SIGN_OUT";
const AUTH_GET_SESSION = "AUTH_GET_SESSION";
const AUTH_CHANGING = "AUTH_CHANGING";

// ------------------ Action creators ------------------
export const signInSuccess = (user) => ({type: SIGN_IN_SUCCESS, payload: user});
export const signInFail = (error_message) => ({type: SIGN_IN_FAIL, payload: error_message});
export const signInLoading = () => ({type: SIGN_IN_LOADING, payload: true});
export const signOut = () => ({type: SIGN_OUT, payload: null});
export const authGetSession = (user) => ({type: AUTH_GET_SESSION, payload: user});
export const authChanging = (user) => ({type: AUTH_CHANGING, payload: user});

// ------------------ Thunks ------------------
export const fetchUser = (email, password) => {
    return (dispatch) => {
        dispatch(signInLoading());

        supabase.auth.signInWithPassword({
            email: email,
            password: password
        })
        .then((result) => {
            if(result.data.session){
                dispatch(signInSuccess(result.data.session));
            } else {
                let error_message = null;

                switch(result.error.message) {
                    case "Invalid login credentials":
                        error_message = "Nieprawidłowe dane. Wprowadź proszę poprawne.";
                        break;
                    case "Email not confirmed":
                        error_message = "Proszę potwierdź konto klikając w link wysłany przez email.";
                        break;
                    default:
                }

                if(error_message){
                    dispatch(signInFail(error_message));
                } else {
                    dispatch(signInFail(result.error.message));
                }
            }
        })
        .catch((err) => {
        })
    }
};

export const authGetSessionThunk = () => {
    return (dispatch) => {
        supabase.auth.getSession().then((result) => {
            dispatch(authGetSession(result.data.session));
        });
    }
}

export const observeAuthChange = (appAuth) => {
    return (dispatch) => {
        const auth = supabase.auth.onAuthStateChange((_event, session) => {
            dispatch(authChanging(session));
        });
    };
};

export const signOutThunk = (appAuth) => {
    return (dispatch) => {
        supabase.auth.signOut()
        .then((result) => {
            dispatch(signOut());
        })
    };
}

// ------------------ Reducer ------------------
function authentication(state = initialState, action){
    switch(action.type){
        case SIGN_IN_SUCCESS:
            return {...state, user: action.payload, success: true, loading: false};
        case SIGN_IN_FAIL:
            return {...state, error: action.payload, loading: false};
        case SIGN_IN_LOADING:
            return {...state, loading: true, error: ""};
        case SIGN_OUT:
            return {...state, user: action.payload, success: false};
        case AUTH_GET_SESSION:
            return {...state, user: action.payload, authIsReady: true};
        case AUTH_CHANGING:
            return {...state, user: action.payload, authIsReady: true}
        default:
            return state;
    }
}

export default authentication;